<template>
  <svg
    class="inline"
    :width="size"
    :height="size"
    fill="none"
    stroke="currentColor"
    :stroke-width="thin ? 1.25 : 2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <use :xlink:href="require('@/assets/icons/feather-sprite.svg') + `#${name}`"/>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '24'
    },
    thin: {
      type: Boolean,
      default: false
    }
  }
}
</script>
