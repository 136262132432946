import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import AuthManagement from 'feathers-authentication-management/src/client'
import io from 'socket.io-client'
import feathersVuex from 'feathers-vuex'
import { iff, discard } from 'feathers-hooks-common'

const host = process.env.NODE_ENV === 'development' ? 'http://localhost:3030' : ''
const socket = io(host, { transports: ['websocket'] })

const feathersClient = feathers()
  .configure(socketio(socket, {
    timeout: 12000
  }))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    },
    error: {
      all: [
        context => {
          if (context.path !== 'authentication' && context.error?.code === 401) {
            console.error(context.error.name)
            window.location.replace(window.location.href)
          }
          return context
        }
      ]
    }
  })

const authManagement = new AuthManagement(feathersClient)

export default feathersClient

const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api',
    idField: '_id',
    whitelist: ['$regex', '$options']
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex, authManagement, socket }
