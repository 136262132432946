import { makeFindMixin } from 'feathers-vuex'

import FolderForm from '@/components/FolderForm'
import FolderEditable from '@/components/FolderEditable'
import FoldersDraggable from '@/components/FoldersDraggable'

export default {
  components: {
    FolderForm, FolderEditable, FoldersDraggable
  },
  mixins: [
    makeFindMixin({ service: 'folders', watch: true })
  ],
  computed: {
    foldersParams() { return { query: {}, temps: !this.connected } },
    foldersQueryWhen() { return this.connected },
    selectedFolder() {
      if (!['today', 'upcoming', 'completed', 'all', 'archived', 'search', 'desk'].some(s => this.$route.fullPath.includes(s))) {
        return this.findFoldersInStore({ query: { _id: this.$route.params.folderId }, temps: !this.connected }).data[0]
      }
      return {}
    },
    sortedFolders() {
      if (this.folders) {
        return [].concat(this.folders[0]).concat(this.folders.slice(1).sort((a, b) => a.order - b.order))
      }
      return []
    },
    parentFolders() {
      return this.folders.slice(1).filter(f => !f.parentId)
    }
  }
}
