<template>
  <div
    class="overflow-hidden max-w-full relative flex-shrink-0 mx-3 mt-2 bg-white border-2 border-gray-200 transition-all duration-200 ease-in-out cursor-pointer select-none group hover:border-gray-400 first:mt-3.5 last:mb-12"
    :class="[{ 'border-blue-400': selected }, compact ? 'rounded-lg mb-1' : 'rounded-xl mb-3' ]"
    @click.prevent="onSelectNote(note)"
  >
    <div class="overflow-hidden max-w-full leading-tight text-gray-800 whitespace-nowrap overflow-ellipsis"
      :class="[ compact ? 'px-3 py-2 font-normal' : 'pt-5 pr-12 pl-6 font-medium' ]"
      v-html="highlightText(note.title)"></div>
    <div v-if="!note.archived" @click.stop="pinNote(!note.pinned)" class="absolute opacity-0 group btn-icon-circle group-hover:opacity-75" :class="[{ 'text-black' : selected }, compact ? 'top-0.5 right-0.5 w-8 h-8' : 'top-2.5 right-2.5' ]" :title="note.pinned ? 'Unpin Note' : 'Pin Note'">
      <img v-if="note.pinned" class="opacity-75" src="../assets/icons/note/icon-pinned.svg" alt="Pinned" width="24" height="24"/>
      <img v-else class="opacity-75" src="../assets/icons/note/icon-pin.svg" alt="Pin" width="24" height="24"/>
    </div>
    <p v-if="!compact" class="overflow-hidden px-6 pb-3 my-2 max-w-full h-11 text-sm leading-normal word-break" :class="[ selected ? 'text-gray-800' : 'text-gray-500' ]" v-html="highlightText(noteContent(note.content))"></p>
    <div v-if="!compact" class="px-6 pb-5 text-xs text-gray-400">
      <span class="text-blue-700" v-if="note.endDate">{{ formatDate(note.endDate) }}</span>
      <span title="Last edited">{{ note.endDate ? ' &bull; ' : '' }} {{ fromNow }}</span>
    </div>
  </div>
</template>

<script>
import { endOfYear, format, formatDistanceToNowStrict, isBefore, parseISO, subMonths, subYears } from 'date-fns'

export default {
  name: 'NoteItem',
  props: {
    note: Object,
    selectedNoteId: {
      type: String,
      default: ''
    },
    compact: {
      type: Boolean,
      default: false
    },
    query: {
      type: String,
      default: ''
    },
    onSelectNote: Function
  },
  computed: {
    selected() {
      return this.selectedNoteId && this.note._id === this.selectedNoteId
    },
    fromNow() {
      const parsed = parseISO(this.note.updatedAt)
      if (isBefore(parsed, endOfYear(subYears(new Date(), 1)))) {
        return format(parsed, 'dd MMM yyyy')
      } else if (isBefore(parsed, subMonths(new Date(), 1))) {
        return format(parsed, 'dd MMM')
      } else {
        return formatDistanceToNowStrict(parsed)
          .replace(/\s\w+$/, unit => ({
            ' second': 's',
            ' seconds': 's',
            ' minute': 'm',
            ' minutes': 'm',
            ' hour': 'h',
            ' hours': 'h',
            ' day': 'd',
            ' days': 'd',
            ' month': 'mth'
          }[unit]))
      }
    }
  },
  methods: {
    pinNote(bool) {
      const data = { pinned: bool }
      if (!bool && this.note.pinnedOrder !== null) {
        data.pinnedOrder = null
      }
      this.note.patch({ data: data })
    },
    highlightText(words) {
      if (!this.query) return words
      const reg = new RegExp((this.query.trim() + '').replace(/([\\.+*?[^\]$(){}=!<>|:])/g, '\\$1'), 'ig')
      let matchedIndex = 0
      const filteredWords = words.toString().replace(reg, (matchedTxt, a, b) => {
        matchedIndex = words.indexOf(matchedTxt)
        return ('<mark>' + matchedTxt + '</mark>')
      })
      if (matchedIndex > 75) {
        if (matchedIndex < filteredWords.length - 75) { return filteredWords.substring(matchedIndex - 30, matchedIndex + 50) } else {
          return filteredWords.substring(matchedIndex - 50, matchedIndex + 30)
        }
      } else {
        return filteredWords
      }
    },
    noteContent(content) {
      if (!this.query) return content.replace(/<(?:.|\n)*?>/gm, ' ').substring(0, 400)
      return content.replace(/<(?:.|\n)*?>/gm, ' ')
    },
    formatDate(date) {
      return format(parseISO(date), 'dd MMM yyyy')
    }
  }
}
</script>
