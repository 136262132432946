<template>
  <div
    class="flex flex-shrink-0 justify-center items-center w-4.5 h-4.5 bg-white rounded-full border-2 transition-all ring-opacity-30 hover:ring-opacity-30"
    :class="[ value ? 'text-white bg-gray-300 border-transparent hover:bg-gray-400': disabled ? 'text-transparent ' + priorityClass : 'text-transparent hover:ring-6 hover:ring-green-500 hover:text-green-500 hover:border-green-500 ' + priorityClass ]"
  >
    <input type="checkbox" class="absolute w-6 h-6 opacity-0 cursor-pointer" v-model="proxyValue" @click.stop>
    <svg class="block pointer-events-none" viewBox="0 0 18 18" fill="none"><path class="fill-current" d="M15.0516 5.80322L7.64443 14.3499L2.66504 9.37051L4.29138 7.74416L7.52412 10.9769L13.3135 4.29688L15.0516 5.80322Z"/></svg>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'

export default {
  name: 'TaskCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    priority: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    proxyValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    priorityClass() {
      const p = {
        1: 'border-red-600 ring-red-500 ring-5',
        2: 'border-yellow-500 ring-yellow-500 ring-4',
        3: 'border-blue-500 ring-blue-500 ring-3',
        4: 'border-gray-400 ring-0'
      }
      return p[this.priority ?? 4]
    }
  },
  methods: {
    onInput: debounce(function(event) {
      if (!this.disabled) {
        this.checked = event.target.value
        this.$emit('input', event.target.value)
      }
    }, 250)
  }
}
</script>
