<template>
  <div class="py-3 pl-4 my-1 max-w-4xl rounded-xl hover:bg-gray-50" v-click-outside="vcoConfig">
    <div v-if="!isOpen" class="inline-flex items-baseline w-full text-gray-500 cursor-pointer group hover:text-gray-900" @click.prevent="toggleTaskForm">
      <span class="mr-4 ml-0.5">
        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M13 7H7V13H6V7H0V6H6V0H7V6H13V7Z" class="fill-current"/></svg>
      </span>
      <span class="pl-0.5 pb-px border-b border-transparent transition-all group-hover:border-black">New Task</span>
    </div>
    <form v-else class="block" @submit.prevent="createTask" @keyup.esc="hideAndReset">
      <div class="flex flex-shrink-0 mr-4 -ml-4 rounded-2xl">
        <task-checkbox class="mx-4 mt-1" :priority="newTask.priority" :disabled="true"/>
        <div class="flex-nowrap pb-0.5 flex-1">
          <div class="relative flex-1">
            <input class="block pt-0.5 pr-6 pb-1 pl-0 w-full bg-transparent border-gray-300 focus:border-black border-b outline-none" :class="{ 'border-red-600': error }" ref="title" v-model="newTask.title"
              type="text" title="Press Enter to Add Task, Esc to close" />
            <button class="absolute top-0 right-0 bottom-0 p-1 font-semibold text-gray-300 border-none hover:text-gray-800 disabled:text-gray-200" type="submit" title="Add Task" :disabled="!newTask.title">↵</button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap ml-8">
        <v-date-picker v-model="newTaskEndDate" :attributes="attrs"
          :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
          :masks="{weekdays: 'WWW'}"
          :popover="{visibility: 'click'}"
        >
          <template #default="{ togglePopover }">
            <a class="inline-flex items-center px-3 mt-1 mr-1 text-sm text-gray-500 bg-transparent rounded-full border border-gray-300 cursor-pointer hover:text-gray-700 group hover:border-gray-700" @click.prevent.stop="dateSelected($event, togglePopover)">
              <img class="mr-3 w-4 opacity-50 lg:mr-2 group-hover:opacity-75" alt="Folder" src="@/assets/icons/icon-nav-calendar.svg" width="16" height="16"/>
              <span class="py-px leading-6">{{ newTaskEndDate }}</span>
            </a>
          </template>
        </v-date-picker>
        <v-popover placement="bottom-start" @apply-show="popoverShow" @apply-hide="popoverHide">
          <a class="inline-flex items-center px-3 mt-1 mr-1 text-sm text-gray-500 bg-transparent rounded-full border border-gray-300 cursor-pointer hover:text-gray-700 group hover:border-gray-700" title="Folder">
            <img class="mr-3 w-4 opacity-50 lg:mr-2 group-hover:opacity-75" alt="Folder" src="@/assets/icons/icon-folder.svg" width="16" height="16"/>
            <span class="py-px leading-6">{{ newTaskFolder.title }}</span>
          </a>
          <template slot="popover">
            <ul class="pb-2 folder-option" v-if="!loading">
              <li>Folders</li>
              <li v-close-popover v-for="folder in folders" :key="folder._id" @click.prevent="setFolder(folder._id)" :class="{ 'selected': folder._id === newTask.folderId }">
                <span>{{ folder.title }}</span>
              </li>
            </ul>
          </template>
        </v-popover>
        <v-popover placement="bottom-start" @apply-show="popoverShow" @apply-hide="popoverHide">
          <a class="inline-flex items-center px-3 mt-1 mr-1 text-sm text-gray-500 bg-transparent rounded-full border border-gray-300 cursor-pointer hover:text-gray-700 group hover:border-gray-700" title="Priority">
            <img class="mr-3 w-4 opacity-50 lg:mr-2 group-hover:opacity-75" :class="{ 'opacity-100': newTask.priority < 4 }" alt="Priority" :src="require(`@/assets/icons/task/icon-priority${newTask.priority < 4 ? `-${newTask.priority}` : '' }.svg`)" width="16" height="16"/>
            <span class="py-px leading-6">{{ newTaskPriority }}Priority</span>
          </a>
          <template slot="popover">
            <div class="pt-2 pb-1 pl-3 text-gray-400">Priority</div>
            <div class="px-1">
              <a class="inline-block" v-close-popover @click="setPriority(1)" title="High Priority">
                <img src="../assets/icons/task/icon-priority-high.svg" alt="High Priority" width="40" height="40"/>
              </a>
              <a class="inline-block" v-close-popover @click="setPriority(2)" title="Medium Priority">
                <img src="../assets/icons/task/icon-priority-medium.svg" alt="Medium Priority" width="40" height="40"/>
              </a>
              <a class="inline-block" v-close-popover @click="setPriority(3)" title="Low Priority">
                <img src="../assets/icons/task/icon-priority-low.svg" alt="Low Priority" width="40" height="40"/>
              </a>
              <a class="inline-block" v-close-popover @click="setPriority(4)" title="No Priority">
                <img src="../assets/icons/task/icon-priority-none.svg" alt="No Priority" width="40" height="40"/>
              </a>
            </div>
          </template>
        </v-popover>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import vClickOutside from 'v-click-outside'
import { addDays, format, isSameDay, parseISO } from 'date-fns'
import TaskCheckbox from './TaskCheckbox'

export default {
  props: {
    endDate: {
      type: String
    },
    folders: {
      type: Array
    },
    selectedFolder: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { 'v-date-picker': DatePicker, TaskCheckbox },
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      error: false,
      isOpen: this.defaultOpen,
      isPopoverOpen: false,
      newTask: {
        title: '',
        folderId: '',
        endDate: '',
        priority: 4,
        completed: false
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'outline'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    vcoConfig() {
      return {
        handler: this.hideAndReset,
        isActive: !this.isPopoverOpen // prevent form closing on clicking popover
      }
    },
    newTaskEndDate: {
      get() {
        if (this.newTask.endDate) {
          const endDate = parseISO(this.newTask.endDate)
          if (isSameDay(endDate, new Date())) {
            return 'Today'
          } else if (isSameDay(endDate, addDays(new Date(), 1))) {
            return 'Tomorrow'
          } else {
            return format(endDate, 'd MMM')
          }
        }
        return 'Schedule'
      },
      set(value) {
        this.newTask.endDate = value
      }
    },
    newTaskFolder() {
      if (this.newTask.folderId) {
        return this.folders.find(folder => folder._id === this.newTask.folderId)
      }
      return this.folders[0] ?? {}
    },
    newTaskPriority() {
      switch (this.newTask.priority) {
        case 1: return 'High '
        case 2: return 'Medium '
        case 3: return 'Low '
        default: return ''
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.isOpen = false
    }
  },
  methods: {
    setFolder(folderId) {
      this.$set(this.newTask, 'folderId', folderId)
    },
    setPriority(num) {
      this.$set(this.newTask, 'priority', num)
    },
    toggleTaskForm() {
      this.isOpen = !this.isOpen
      this.error = false
      this.$nextTick(() => this.$refs.title.focus())
      this.setTaskProps()
    },
    dateSelected(e, toggle) {
      toggle({ ref: e.target })
    },
    popoverShow() { this.isPopoverOpen = true },
    popoverHide() { this.isPopoverOpen = false },
    hideAndReset() {
      this.isOpen = false
      this.newTask = {
        title: '',
        folderId: '',
        endDate: '',
        priority: 4,
        completed: false
      }
    },
    createTask() {
      if (this.newTask.title) {
        this.error = false
        const { Task } = this.$FeathersVuex.api
        const task = new Task({
          ...this.newTask,
          folderId: this.newTaskFolder
        })
        task.save().then(() => {
          this.newTask = {
            title: '',
            folderId: '',
            endDate: '',
            priority: 4,
            completed: false
          }
          this.setTaskProps()
        }).catch(e => console.error(e))
      } else {
        this.error = true
      }
    },
    setTaskProps() {
      if (['today', 'upcoming', 'desk', 'calendar'].some(s => this.$route.fullPath.includes(s))) {
        this.$set(this.newTask, 'folderId', this.newTaskFolder._id)
        this.$set(this.newTask, 'endDate', this.endDate)
      } else {
        this.$set(this.newTask, 'folderId', this.selectedFolder._id)
      }
    }
  },
  mounted() {
    if (this.endDate) {
      this.$set(this.newTask, 'endDate', this.endDate)
    }
  }
}
</script>
