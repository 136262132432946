import { Node, Plugin } from 'tiptap'
import { wrappingInputRule, sinkListItem, splitToDefaultListItem, liftListItem } from 'tiptap-commands'
import TodoItemComponent from './TodoItemComponent'
import ObjectID from 'bson-objectid'

export default class TodoItem extends Node {
  get name() {
    return 'todo_item'
  }

  get defaultOptions() {
    return {
      nested: false
    }
  }

  get view() {
    return TodoItemComponent
  }

  get schema() {
    return {
      attrs: {
        blockId: {
          default: null
        },
        done: {
          default: false
        },
        priority: {
          default: 4
        }
      },
      draggable: true,
      content: this.options.nested ? '(paragraph|todo_list)+' : 'paragraph+',
      toDOM: node => {
        const { blockId, done, priority } = node.attrs

        return [
          'li',
          {
            'data-type': this.name,
            'data-done': done.toString(),
            'data-block-id': blockId,
            'data-priority': priority
          },
          ['span', { class: 'todo-checkbox', contenteditable: 'false' }],
          ['div', { class: 'todo-content' }, 0]
        ]
      },
      parseDOM: [{
        priority: 51,
        tag: `[data-type="${this.name}"]`,
        getAttrs: dom => ({
          blockId: dom.getAttribute('data-block-id'),
          done: dom.getAttribute('data-done') === 'true',
          priority: dom.getAttribute('data-priority') || 4
        })
      }]
    }
  }

  get plugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          const newTr = newState.tr
          let modified = false
          newState.doc.descendants((node, pos) => {
            if (!!node.type && (node.type.name === 'todo_item')) {
              const { blockId, ...rest } = node.attrs
              if (blockId === undefined || blockId === null || blockId === '') {
                // Adds a unique id to a node
                newTr.setNodeMarkup(pos, undefined, { blockId: ObjectID().toString(), ...rest })
                modified = true
              }
            }
          })

          if (modified) {
            return newTr
          }
        }
      })
    ]
  }

  keys({ type }) {
    return {
      Enter: splitToDefaultListItem(type),
      Tab: this.options.nested ? sinkListItem(type) : () => {},
      'Shift-Tab': liftListItem(type)
    }
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(/^\s*(\[\])\s|(\[ \])\s$/, type)
    ]
  }
}
