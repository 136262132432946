<template>
  <div class="flex overflow-hidden z-10 flex-col pb-14 h-full max-h-screen bg-white transition-all duration-300 ease-out transform lg:ease-in lg:pb-0 lg:duration-150" :class="[ showNoteListMobile ? 'opacity-100 scale-100' : 'opacity-0 scale-90 lg:scale-100 lg:opacity-100' ]">
    <div class="flex items-center p-2 h-12 lg:h-auto">
      <div class="block lg:hidden">
        <a @click.prevent="$store.commit('toggleSidebar')" class="btn-icon-circle" title="Menu">
          <img src="@/assets/icons/icon-nav-menu.svg"/>
        </a>
      </div>
      <div class="overflow-hidden text-xl font-semibold leading-loose whitespace-nowrap transition-all ease-out tracking-xl overflow-ellipsis" :class="[ searchOpen ? 'transform -translate-x-20 w-0 mx-0 p-0' : 'mr-2 ml-2 lg:ml-7 w-full' ]">
        {{ pageTitle }}
      </div>
      <div class="relative flex-grow mx-2 text-right group">
        <input @input="onInputQuery" class="h-10 text-sm placeholder-transparent rounded-full transition-all duration-200 ease-out group-focus:border-gray-500 focus:placeholder-gray-400" :class="[ searchOpen ? 'py-2.5 pr-8 pl-5 w-full border bg-white border-gray-400 placeholder-gray-400 text-black' : 'py-2 px-4 w-10 bg-transparent group-hover:bg-gray-100 text-transparent' ]" ref="search" placeholder="Search Note" @keyup.esc="onCloseSearch"/>
        <span v-if="searchOpen" class="absolute top-0 right-0 z-10 p-2 leading-3 opacity-50 cursor-pointer group-hover:opacity-75" title="Press ESC to close" @click.prevent="onCloseSearch">
          <img src="../assets/icons/icon-close.svg"/>
        </span>
        <span v-else class="absolute top-0 right-0 z-10 p-2 leading-3 opacity-50 cursor-pointer" title="Search Note" @click="openSearchBar">
          <img src="../assets/icons/icon-search.svg"/>
        </span>
      </div>
      <v-popover v-if="this.$route.params.folderId !== 'archived'" placement="bottom">
        <a title="Create New Note" class="btn-icon-circle">
          <img src="../assets/icons/note/icon-new-note.svg"/>
        </a>
        <template slot="popover">
          <div class="pt-2 pop-menu">
            <div class="pb-3 pop-menu-title">Templates</div>
            <div class="pop-menu-item">
              <a v-close-popover @click.prevent="onCreateNoteClick">
                <img src="../assets/icons/note/icon-new-note.svg"/> Blank Note
              </a>
            </div>
            <div class="pop-menu-item">
              <a v-close-popover @click.prevent="onCreateNoteTemplate('project')">
                <img src="../assets/icons/note/icon-note-template.svg"/> Project Plan
              </a>
            </div>
            <div class="pop-menu-item">
              <a v-close-popover @click.prevent="onCreateNoteTemplate('journal')">
                <img src="../assets/icons/note/icon-note-template.svg"/> Daily Journal
              </a>
            </div>
            <div class="pop-menu-item">
              <a v-close-popover @click.prevent="onCreateNoteTemplate('meeting')">
                <img src="../assets/icons/note/icon-note-template.svg"/> Meeting Note
              </a>
            </div>
          </div>
        </template>
      </v-popover>
      <a v-else
        title="Delete All Archived"
        class="flex-shrink-0 btn-icon-circle"
        @click.prevent="$emit('delete-all-archived')"
      >
        <img src="../assets/icons/icon-delete.svg"/>
      </a>
    </div>
    <div class="overflow-y-auto overflow-x-hidden h-full lg:overflow-y-hidden lg:hover:overflow-y-auto">
      <div v-if="notes.length > 0" class="overflow-hidden w-screen lg:w-90" :class="{ 'opacity-30': isSearching }">
        <h6 class="mt-3 mb-3 ml-9 text-xs font-semibold leading-4 text-gray-500 uppercase tracking-px" v-if="pinnedNotes.length > 0">Pinned</h6>
        <note-item
          v-for="note in pinnedNotes" :key="note._id"
          :note="note" :query="query" :selectedNoteId="selectedNoteId"
          :onSelectNote="onSelectNote"
        />
        <h6 class="mt-9 mb-3 ml-9 text-xs font-semibold leading-4 text-gray-500 uppercase tracking-px" v-if="pinnedNotes.length > 0">Others</h6>
        <note-item
          v-for="note in unpinnedNotes" :key="note._id"
          :note="note" :query="query" :selectedNoteId="selectedNoteId"
          :onSelectNote="onSelectNote"
        />
      </div>
      <div v-else>
        <div v-if="query.length > 0">
          <div class="py-4 px-9 text-gray-400">
            No note found
          </div>
        </div>
        <div v-else-if="this.$route.params.folderId !== 'archived'" class="py-12 px-6 h-full text-center text-gray-400">
          <a class="pb-0.5 border-b border-gray-300" @click.prevent="onCreateNoteClick">Create a new note</a>
        </div>
        <div v-else class="py-4 px-9 text-gray-400">
          No archived note
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import NoteItem from '@/components/NoteItem'

export default {
  props: [
    'loading',
    'filteredNotes',
    'selectedNoteId',
    'selectedFolder',
    'showNoteListDesktop',
    'showNoteListMobile'
  ],
  components: { NoteItem },
  data() {
    return {
      query: '',
      searchOpen: false,
      isSearching: false
    }
  },
  computed: {
    pageTitle() {
      return this.$route.params.folderId === 'archived'
        ? 'Archived'
        : this.selectedFolder?.title ?? 'All Notes'
    },
    notes() {
      if (this.query) {
        return this.filteredNotes.filter(note => {
          const sameTitle = note.title.toLowerCase().includes(this.query.toLowerCase())
          const sameContent = note.content.toLowerCase().includes(this.query.toLowerCase())
          return sameTitle || sameContent
        })
      }
      return this.filteredNotes
    },
    pinnedNotes() {
      return this.notes.filter(note => note.pinned)
    },
    unpinnedNotes() {
      return this.notes.filter(note => !note.pinned)
    }
  },
  created() {
    this.updateQuery = debounce(this.updateQuery, 750)
  },
  methods: {
    onInputQuery(event) {
      this.isSearching = true
      this.updateQuery(event)
    },
    updateQuery(event) {
      this.query = event.target.value
      this.isSearching = false
    },
    openSearchBar() {
      if (this.searchOpen) {
        this.searchOpen = false
      } else {
        this.searchOpen = true
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    onCreateNoteClick() {
      this.$emit('create-note')
    },
    onCreateNoteTemplate(type) {
      this.$emit('create-note-template', type)
    },
    onSelectNote(note) {
      this.$emit('select-note', note._id)
    },
    onCloseSearch(e) {
      this.$refs.search.value = ''
      this.query = ''
      this.searchOpen = false
      e.target.blur()
    }
  }
}
</script>
