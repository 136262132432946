import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', ['user']),
    settings() {
      return JSON.parse(this.user.settings)
    }
  },
  methods: {
    writeSettings(key, value) {
      const newSettings = this.settings
      newSettings[key] = value
      this.user.patch({ data: { settings: JSON.stringify(newSettings) } })
    }
  }
}
