<template>
  <div class="pr-2.5 pl-6 my-0 text-bg-700 text-sm">
    <div class="flex items-baseline mt-2 w-full">
      <h6 class="flex-1 text-xs font-bold text-gray-600 uppercase select-none tracking-px lg:ml-2.5 lg:mt-5 lg:mb-4">Folders</h6>
      <a class="-mr-1 btn-icon-circle" title="New folder" @click.stop="toggleFolderForm">
        <svg class="fill-current" width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg"><path d="M11 6H6L6 11H5V6H0V5H5V0H6L6 5H11V6Z"/></svg>
      </a>
    </div>
    <form
      v-if="addingFolder"
      class="mr-1.5 ml-2.5 mb-4"
      @submit.prevent="createNewFolder"
      @keyup.esc="toggleFolderForm"
    >
      <div @click.stop>
        <input ref="title" placeholder="Folder Name" class="py-2 px-3 mt-1 mb-2 w-full rounded border border-gray-300 focus:outline-none focus:border-gray-700" v-model="folderTitle" type="text"/>
      </div>
      <div class="flex">
        <button class="flex-1 mr-2 text-sm btn outline" @click.stop="toggleFolderForm">Close</button>
        <button class="flex-1 text-sm btn" submit="btn" @click.stop>Create</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addingFolder: false,
      folderTitle: ''
    }
  },
  methods: {
    toggleFolderForm() {
      this.addingFolder = !this.addingFolder
      if (this.addingFolder) {
        this.$nextTick(() => this.$refs.title.focus())
      } else {
        this.folderTitle = ''
      }
    },
    createNewFolder() {
      if (this.folderTitle) {
        const { Folder } = this.$FeathersVuex.api
        const folder = new Folder({ title: this.folderTitle })
        folder.save().then(() => {
          this.folderTitle = ''
        }).catch(err => {
          alert(err.message)
        })
      }
    }
  }
}
</script>
