<template>
  <div v-if="note">
    <div class="flex fixed top-0 right-0 left-0 z-50 justify-between py-0.5 px-3.5 bg-white lg:py-2.5">
      <span class="flex items-center">
        <a class="flex btn-icon-circle lg:hidden" @click.prevent="backToNoteList">
          <img src="@/assets/icons/note/icon-back.svg" alt="Back"/>
        </a>
        <a title="Full Screen" class="hidden lg:inline-block" v-if="!openSidebar && showNoteListDesktop"
          @click.prevent="toggleNoteFullScreen(true)">
          <div class="btn-icon-circle">
            <feather-icon name="maximize-2" size="20"/>
          </div>
        </a>
        <a title="Full Screen" class="hidden lg:inline-block" v-else-if="openSidebar && showNoteListDesktop"
          @click.prevent="toggleNoteFullScreen(false)">
          <div class="btn-icon-circle">
            <feather-icon name="maximize-2" size="20"/>
          </div>
        </a>
        <a title="Default" class="hidden lg:inline-block" v-else
          @click.prevent="toggleNoteFullScreen(false)">
          <div class="btn-icon-circle">
            <feather-icon name="minimize-2" size="20"/>
          </div>
        </a>
        <p v-if="note.archived" disabled>Archived</p>
        <v-popover v-else placement="top-start">
          <a class="inline-flex items-center px-4 ml-3 w-auto h-10 text-sm leading-5 text-gray-600 rounded-full cursor-pointer hover:bg-gray-100 hover:text-black lg:text-base" title="Change Note Folder" v-if="noteFolder">
            <img class="mr-3 w-5 opacity-50 hover:opacity-75" alt="Folder" src="@/assets/icons/icon-folder.svg"/>
            {{ noteFolder.title }}
          </a>
          <template slot="popover">
            <div>
              <ul class="pb-2 folder-option" v-if="noteFolder">
                <li>Folders</li>
                <li v-for="folder in folders" :key="folder._id" @click="setNoteFolder(folder._id)" v-close-popover :class="{ 'selected': folder._id === noteFolder._id }">
                  <span>{{ folder.title }}</span>
                </li>
              </ul>
            </div>
          </template>
        </v-popover>
      </span>
      <span class="flex items-center">
        <div v-show="saved" class="flex ml-2 w-10 h-10" title="Saved">
          <img src="@/assets/icons/note/icon-saved.svg" alt="Saved" class="m-auto"/>
        </div>
        <span v-show="note.archived" class="mr-4 text-gray-400">Read-only</span>
        <div v-if="note.endDate" class="hidden lg:inline-flex">
          <v-popover placement="top-start">
            <a title="Note Date" class="inline-flex items-center px-4 ml-3 w-auto h-10 leading-5 text-blue-700 bg-blue-50 rounded-full cursor-pointer">
              {{ formatEndDate }}
            </a>
            <template slot="popover">
              <div class="py-2 px-4">
                <a @click.prevent="noteSelectedEndDate=null">Remove date</a>
              </div>
            </template>
          </v-popover>
        </div>
        <a class="hidden ml-2 lg:inline-flex btn-icon-circle" @click.prevent="pinNote(true)" title="Pin Note" v-if="!note.archived && !note.pinned">
          <img src="@/assets/icons/note/icon-pin.svg" alt="Pin"/>
        </a>
        <a class="hidden ml-2 lg:inline-flex btn-icon-circle" @click.prevent="pinNote(false)" title="Unpin Note" v-if="!note.archived && note.pinned">
          <img src="@/assets/icons/note/icon-pinned.svg" alt="Pinned"/>
        </a>
        <v-popover placement="top-start" :hideOnTargetClick="false">
          <a class="inline-flex ml-2 btn-icon-circle" title="Note Info">
            <img width="18px" src="@/assets/icons/icon-more.svg" alt="Date"/>
          </a>
          <template slot="popover">
            <div class="pt-2 pop-menu">
              <div class="pop-menu-item" v-show="!note.archived">
                <a title="Pin Note" @click.prevent="pinNote(true)" v-show="!note.pinned">
                  <img src="@/assets/icons/note/icon-pin.svg" alt="Pin"/> Pin Note
                </a>
                <a title="Unpin Note" @click.prevent="pinNote(false)" v-show="note.pinned">
                  <img src="@/assets/icons/note/icon-pinned.svg" alt="Pin"/> Unpin Note
                </a>
              </div>
              <div class="pop-menu-item" v-show="!note.archived">
                <v-popover>
                  <a><img alt="Folder" src="@/assets/icons/icon-folder.svg"/> Move to Folder</a>
                  <template slot="popover">
                    <div>
                      <ul class="pb-2 folder-option" v-if="noteFolder">
                        <li>Folders</li>
                        <li v-for="folder in folders" :key="folder._id" @click="setNoteFolder(folder._id)" v-close-popover :class="{ 'selected': folder._id === noteFolder._id }">
                          <span>{{ folder.title }}</span>
                        </li>
                      </ul>
                    </div>
                  </template>
                </v-popover>
              </div>
              <div class="pop-menu-item">
                <a title="Archive Note" @click.prevent="archiveNote(true)" v-show="!note.archived">
                  <img alt="Archive" src="@/assets/icons/note/icon-archive.svg"/> Move to Archive
                </a>
                <a title="Unarchive Note" @click.prevent="archiveNote(false)" v-show="note.archived">
                  <img alt="Unarchive" src="@/assets/icons/note/icon-unarchive.svg"/> Unarchive Note
                </a>
              </div>
              <div class="text-red-600 pop-menu-item" v-show="note.archived">
                <a title="Delete Note" @click.prevent="deleteNote">
                  <img src="@/assets/icons/icon-delete.svg" alt="Delete"/> Delete Permanently
                </a>
              </div>
              <div class="pop-menu-divider" v-show="!note.archived"></div>
              <div class="pop-menu-item" v-show="!note.archived">
                <v-date-picker v-model="noteSelectedEndDate" :attributes="attrs"
                  :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                  :masks="{ weekdays: 'WWW'}"
                  :popover="{visibility: 'click'}"
                >
                  <template #default="{ togglePopover }">
                    <a class="block" @click.prevent.stop="dateSelected($event, togglePopover)">
                      <img src="@/assets/icons/icon-nav-calendar.svg" alt="Calendar"/>
                      {{ formatEndDate }}
                    </a>
                  </template>
                </v-date-picker>
              </div>
              <div class="pop-menu-divider"></div>
              <div class="pop-menu-item" v-show="!note.archived">
                <a v-if="taskSyncLoading" title="Loading" disabled>
                  <img src="@/assets/icons/note/minus-circle.svg" alt="Loading"/> Syncing Task {{ !noteTaskSyncDisabled && taskSyncCount > 0 ? `... ${noteTasksCount}/${taskSyncCount}` : '...'}}
                </a>
                <a v-else-if="noteTaskSyncDisabled" @click.prevent="toggleTaskSync" title="Enable sync">
                  <img src="@/assets/icons/note/pause-circle.svg" alt="Disabled"/> Task Sync Disabled
                </a>
                <a v-else @click.prevent="toggleTaskSync" title="Disable sync and delete tasks">
                  <img src="@/assets/icons/note/play-circle.svg" alt="Enabled"/> Task Sync Enabled
                </a>
              </div>
              <div class="pop-menu-item" v-show="noteTasksCount > 0">
                <a @click="taskCleanup" title="Click to re-count"><img src="@/assets/icons/icon-nav-task.svg" alt="Task Count"/> Task Count: {{ noteTasksCount }}</a>
              </div>
              <div class="pop-menu-item">
                <span><img src="@/assets/icons/icon-nav-note.svg" alt="Delete"/> Word Count: {{ noteWordsCount }}</span>
              </div>
              <div class="pop-menu-item" v-if="noteLastEdited">
                <span :title="`Created ${noteCreated}`"><img src="@/assets/icons/icon-info.svg" alt="Delete"/> Edited {{ noteLastEdited }}</span>
              </div>
            </div>
          </template>
        </v-popover>
      </span>
    </div>
    <div>
      <div class="px-5 pt-5 pb-3 my-0 mx-auto max-w-3xl lg:pt-10 lg:pb-6">
        <div v-if="note.archived" class="block p-0 w-full h-auto text-2xl font-semibold bg-transparent border-none tracking-2xl lg:text-3xl lg:tracking-3xl">{{ note.title }}</div>
        <editable v-else ref="headline" class="block p-0 w-full h-auto text-2xl font-semibold bg-transparent border-none tracking-2xl lg:text-3xl lg:tracking-3xl" type="text" :content="note.title" @update="updateHeadline" placeholder="Untitled Note"/>
      </div>
      <div>
        <editor
          :key="note._id"
          v-model="noteContent"
          @changes-made="changesMade"
          @editor-blur="editorBlur"
          :archived="note.archived"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash-es'
import { format, parseISO } from 'date-fns'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import Editable from '@/components/Editable'
import Editor from '@/components/editor/Editor'

export default {
  name: 'Note',
  components: {
    Editable,
    Editor,
    'v-date-picker': DatePicker
  },
  props: ['note', 'showNoteListDesktop', 'selectedNoteId', 'folders', 'openSidebar'],
  data() {
    return {
      saved: false,
      taskSyncLoading: false,
      taskSyncCount: 0,
      showToolbar: false,
      showCalendar: false,
      attrs: [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'outline'
          },
          dates: new Date()
        }
      ]
    }
  },
  watch: {
    async isPatchNotesPending(val) {
      if (!val) {
        await new Promise(resolve => setTimeout(resolve, 400))
        this.saved = true
      }
    },
    isCreateTasksPending(val) {
      if (val) {
        this.taskSyncLoading = val
      } else {
        this.debounceTaskPending(val)
      }
    }
  },
  computed: {
    ...mapState('notes', { isPatchNotesPending: 'isPatchPending' }),
    ...mapState('tasks', { isCreateTasksPending: 'isCreatePending' }),
    ...mapGetters('notes', { findNotesInStore: 'find' }),
    ...mapGetters('tasks', { findTasksInStore: 'find' }),
    ...mapGetters('folders', { getFolderInStore: 'get' }),
    noteContent: {
      get() {
        return this.note.content
      },
      set: debounce(function(val) {
        this.note.patch({ data: { content: val } })
      }, 1000, { maxWait: 5000 })
    },
    noteSelectedEndDate: {
      get() {
        return this.note.endDate ? parseISO(this.note.endDate) : null
      },
      set(val) {
        this.note.patch({ data: { endDate: val || null } })
      }
    },
    formatEndDate() {
      return this.noteSelectedEndDate ? format(this.noteSelectedEndDate, 'dd MMM yyyy') : 'Set Note Date'
    },
    noteTaskSyncDisabled() {
      return this.note.taskSync === false
    },
    noteTasksCount() { return this.findTasksInStore({ query: { noteId: this.note._id } }).data.length },
    noteWordsCount() {
      if (this.note.content && this.note.content.replace(/(<([^>]+)>)/gi, '').match(/\w+/g) !== null) {
        return this.note.content.replace(/(<([^>]+)>)/gi, '').match(/\w+/g).length
      }
      return 0
    },
    noteCreated() { return format(parseISO(this.note.createdAt), 'dd MMM yyyy') },
    noteLastEdited() { return this.note.updatedAt ? format(parseISO(this.note.updatedAt), 'dd MMM yyyy') : null },
    noteFolder() {
      return this.getFolderInStore(this.note.folderId)
    }
  },
  methods: {
    dateSelected(e, toggle) {
      toggle({ ref: e.target })
    },
    updateHeadline(val) {
      this.note.patch({ data: { title: val ?? 'Untitled Note' } })
    },
    setNoteFolder(folderId) {
      this.note.patch({ data: { folderId: folderId } })
    },
    archiveNote(bool) {
      this.note.patch({ data: { archived: bool, pinned: false } }).then(() => {
        this.$emit('toggle-note-list-container')
        this.$router.push('/app/notes/all')
      })
    },
    pinNote(bool) {
      const data = { pinned: bool }
      if (!bool && this.note.pinnedOrder !== null) {
        data.pinnedOrder = null
      }
      this.note.patch({ data: data })
    },
    debounceTaskPending: debounce(function(val) {
      this.taskSyncLoading = val
    }, 2500),
    async toggleTaskSync() {
      this.taskSyncCount = 0
      this.taskSyncLoading = true
      if (this.noteTaskSyncDisabled) {
        await this.note.patch({ data: { taskSync: true } })
        this.taskSyncCount = document.querySelectorAll('[data-type="todo_item"]').length
        setTimeout(() => { this.taskSyncLoading = false }, this.taskSyncCount * 100)
      } else {
        await this.note.patch({ data: { taskSync: false } })
        const tasks = await this.findTasksInStore({ query: { noteId: this.note._id } }).data
        tasks.forEach(async task => { await task.remove() })
        this.taskSyncLoading = false
      }
    },
    toggleNoteFullScreen(bool) {
      this.$emit('toggle-desktop-full', bool)
    },
    backToNoteList() {
      // works when app started on mobile view
      // on desktop never going back to folder
      this.$router.go(-1)
      this.showToolbar = false
      this.$emit('toggle-note-list-container', true)
    },
    editorBlur() {},
    async changesMade() {
      await new Promise(resolve => setTimeout(resolve, 800))
      this.saved = false
    },
    deleteNote() {
      const result = confirm('Are you sure want to delete this note?')
      if (result) {
        const { Note } = this.$FeathersVuex.api
        const deleteNote = new Note(this.note)
        deleteNote.remove().then(() => {
          this.$emit('toggle-note-list-container')
          this.$router.push('/app/notes/all')
          // delete all task inside this note
          this.$store.dispatch('tasks/find', { query: { noteId: deleteNote._id } })
            .then(noteTasks => {
              noteTasks.forEach(noteTask => { noteTask.remove() })
            }).catch(err => console.error(err))
        }).catch(err => console.error(err))
      }
    },
    taskCleanup() {
      // find task in store that belongs to this note
      if (this.note.tasks.length > 0) {
        this.note.tasks.forEach(noteTask => {
          if (noteTask._id !== null) {
            // look for task inside note
            const taskFound = document.querySelectorAll(`[data-block-id="${noteTask._id}"]`)
            // if task not found in note
            if (!taskFound[0]) {
              // delete from task store
              const { Task } = this.$FeathersVuex.api
              Task.get(noteTask._id).then(task => {
                const deleteTask = new Task({ _id: task._id })
                deleteTask.remove()
              }).catch(e => {
                console.log('task error', e.message)
              })
            }
          }
        })
      }
    }
  }
}
</script>
