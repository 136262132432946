<template>
  <div class="inline-block relative ml-1 w-14 text-base tabular-nums focus:outline-none group">
    <input
      ref="input"
      placeholder="00:00"
      class="block px-1 -ml-1 max-w-full h-8 text-base tabular-nums leading-8 placeholder-gray-400 text-center bg-transparent rounded border-none disabled:text-gray-300 group-hover:bg-gray-100 group-focus:bg-gray-100"
      @focus="onFocus"
      @blur="debounceBlur"
      @change="onChange"
      @keydown="onKeydown"
      v-model="time"
      :disabled="disabled"
      :id="id"
      :name="name"
    />
    <div class="absolute left-0 top-7 z-10 my-2 w-36 bg-white rounded-lg shadow outline-none" :class="[ showDropdown ? 'block' : 'hidden' ]">
      <div class="overflow-y-scroll py-1 max-h-52 wrapper">
        <div
          class="px-4 leading-10 cursor-pointer option"
          :class="{ 'bg-gray-100': index === selectedIndex }"
          :key="item"
          v-for="(item, index) in dropdownItems"
          @mouseover="onOptionHover(index)"
          @click="onOptionClick(index)"
          :aria-selected="index === selectedIndex"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timepicker',
  props: {
    value: { type: String },
    disabled: { type: Boolean, default: false },
    id: { type: String },
    name: { type: String }
  },
  data() {
    return {
      time: '',
      prevTime: '',
      dropdownItems: [],
      selectedIndex: 0,
      isActive: false,
      showDropdown: false,
      isFocusing: false,
      usingArrow: false,
      debounceTimer: undefined,
      isValid: false
    }
  },
  watch: {
    value: {
      handler: function (val, oldVal) {
        if (this.disabled) { return }
        this.time = val
        this.autoScroll()
      },
      immediate: true
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => this.$refs.input.focus())
    },
    onOptionHover(index) {
      this.selectedIndex = index
    },
    onOptionClick(index) {
      this.selectedIndex = index
      this.time = this.dropdownItems[this.selectedIndex]
      this.$emit('input', this.time)
      this.$refs.input.blur()
    },
    onFocus() {
      if (this.disabled) { return }
      if (!this.isFocusing) {
        this.$refs.input.select()
        this.isFocusing = true
        this.prevTime = this.time
      }
      if (!this.isActive) {
        this.toggleActive()
      }
    },
    toggleActive() {
      if (this.disabled) { return }
      this.isActive = !this.isActive

      if (this.isActive) {
        this.isFocusing = true
        this.$emit('focus')
        this.showDropdown = true
        if (this.time) {
          this.autoScroll()
        }
      } else {
        this.showDropdown = false
        this.$emit('blur')
        this.isFocusing = false
      }
    },
    debounceBlur() {
      if (this.disabled) { return }
      this.isFocusing = false
      window.clearTimeout(this.debounceTimer)
      this.debounceTimer = window.setTimeout(() => {
        window.clearTimeout(this.debounceTimer)
        this.onBlur()
      }, 200)
    },
    onBlur() {
      if (!this.disabled && !this.isFocusing && this.isActive) {
        this.toggleActive()
      }
    },
    onKeydown(evt) {
      const keyPressed = evt.key

      if (evt.key === 'ArrowUp') {
        evt.preventDefault()
        this.usingArrow = true
        if (this.selectedIndex === 0) {
          this.selectedIndex = this.dropdownItems.length - 1
        } else {
          this.selectedIndex--
        }
        this.scrollToSelected()
      } else if (evt.key === 'ArrowDown') {
        evt.preventDefault()
        this.usingArrow = true
        if (this.selectedIndex === this.dropdownItems.length - 1) {
          this.selectedIndex = 0
        } else {
          this.selectedIndex++
        }
        this.scrollToSelected()
      } else if (evt.key === 'Enter') {
        if (this.usingArrow) {
          this.time = this.dropdownItems[this.selectedIndex]
          this.$emit('input', this.time)
          this.isValid = false
        }
        this.isFocusing = false
        this.$refs.input.blur()
      } else if (evt.keyCode === 27) {
        // Blur on Esc
        this.time = ''
        this.$emit('input', '')
        this.$refs.input.blur()
        this.isFocusing = false
      } else if (['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'].includes(keyPressed)) {
        return true
      } else if (keyPressed.match(/\d|:|[AaPpMm]/)) {
        // Allow numbers, colon, am/pm
        this.usingArrow = false
        return true
      } else if ((event.ctrlKey || event.metaKey) && (['a', 'c', 'v', 'x'].includes(keyPressed))) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    onChange() {
      const val = this.$refs.input.value
      if (val.match(/^[0-9][Aa][Mm]$/)) {
        this.time = `0${val[0]}:00`
        this.isValid = true
      } else if (val.match(/^1[0-2][Aa][Mm]$/)) {
        if (parseInt(val[0] + val[1]) === 12) {
          this.time = '00:00'
        } else {
          this.time = `${val[0]}${val[1]}:00`
        }
        this.isValid = true
      } else if (val.match(/^[0-9][Pp][Mm]$/)) {
        this.time = `${parseInt(val[0]) + 12}:00`
        this.isValid = true
      } else if (val.match(/^1[0-2][Pp][Mm]$/)) {
        if (parseInt(val[0] + val[1]) === 12) {
          this.time = '12:00'
        } else {
          this.time = `${parseInt(val[0] + val[1]) + 12}:00`
        }
        this.isValid = true
      } else if (val.match(/^[0-9]$/)) {
        this.time = `0${val[0]}:00`
        this.isValid = true
      } else if (val.match(/^[0-3][0-9]$/)) {
        this.time = `${val[0]}${val[1]}:00`
        this.isValid = true
      } else if (val.match(/^[0-9][0-5][0-9]$/)) {
        this.time = `0${val[0]}:${val[1]}${val[2]}`
        this.isValid = true
      } else if (val.match(/^([0-1][0-9]|2[0-3])[0-5][0-9]$/)) {
        this.time = `${val[0]}${val[1]}:${val[2]}${val[3]}`
        this.isValid = true
      } else if (val.match(/^([0-9]):[0-5][0-9]$/)) {
        this.time = `0${val}`
        this.isValid = true
      } else if (val.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        this.isValid = true
      }

      if (this.isValid) {
        this.autoScroll()
        this.$emit('input', this.time)
        this.isValid = false
      } else {
        if (this.time) {
          this.time = this.prevTime
        }
      }
    },
    autoScroll() {
      const allowedMinutes = ['00', '15', '30', '45']
      let lookupTime = this.time
      if (lookupTime) {
        const minutes = lookupTime.slice(-2)
        if (!allowedMinutes.includes(minutes)) {
          lookupTime = `${lookupTime.slice(0, 3)}${(Math.round(parseInt(minutes) / 15) * 15) % 60}`
        }
      }
      const index = this.dropdownItems.indexOf(lookupTime)
      if (index > -1) {
        this.selectedIndex = index
        this.scrollToSelected()
      }
    },
    scrollToSelected() {
      this.$nextTick(() => {
        const targetList = this.$el.querySelectorAll('.wrapper')[0]
        const targetValue = this.$el.querySelectorAll('.wrapper > .option[aria-selected="true"]')[0]
        if (targetList && targetValue) {
          targetList.scrollTop = (this.selectedIndex * 40) - 80
        }
      })
    }
  },
  created() {
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 4; m++) {
        this.dropdownItems.push(`${h < 10 ? `0${h}` : h}:${m === 0 ? '00' : 15 * m}`)
      }
    }
  },
  mounted () {
    window.clearTimeout(this.debounceTimer)
  },
  beforeDestroy () {
    window.clearTimeout(this.debounceTimer)
  }
}
</script>
