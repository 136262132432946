<template>
  <div id="editor" class="relative px-5 pt-3 pb-32 mx-auto mt-0 mb-20 max-w-3xl min-h-full lg:pb-52 lg:pt-5">
    <editor-menu-bubble v-if="editor && !archived" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input
            class="menububble__input"
            type="text"
            :value="getMarkAttrs('link').href"
            placeholder="https://" ref="linkInput"
            @input="linkUrl = $event.target.value"
            @keydown.esc="hideLinkMenu"
          />
          <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
            <editor-icon name="remove" />
          </button>
        </form>

        <template v-else>
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
            v-tooltip.top="getTooltipOptions('Bold<br/><em>Ctrl + B</em>')"
          >
            <editor-icon name="bold" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
            v-tooltip.top="getTooltipOptions('Italic<br/><em>Ctrl + I</em>')"
          >
            <editor-icon name="italic" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
            v-tooltip.top="getTooltipOptions('Underline<br/><em>Ctrl + U</em>')"
          >
            <editor-icon name="underline" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
            v-tooltip.top="getTooltipOptions('Strikethrough<br/><em>Ctrl + D</em>')"
          >
            <editor-icon name="strike" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.code() }"
            @click="commands.code"
            v-tooltip.top="getTooltipOptions('Inline Code<br/><em>Ctrl + `</em>')"
          >
            <editor-icon name="code" />
          </button>

          <button
            class="menububble__button"
            @click="showLinkMenu(getMarkAttrs('link'))"
            :class="{ 'is-active': isActive.link() }"
            v-tooltip.top="getTooltipOptions('Insert Link')"
          >
            <editor-icon name="link" />
          </button>
        </template>
      </div>
    </editor-menu-bubble>
    <div class="menubar-wrapper">
      <editor-menu-bar v-if="editor && !archived" :editor="editor" v-slot="{ commands, isActive, focused }">
        <div
          class="menubar is-hidden"
          :class="{ 'is-focused': focused }"
        >
          <template v-if="isActive.table()">
            <button class="menubar__button" @click="commands.deleteTable" v-tooltip.top="getTooltipOptions('Delete Table')">
              <editor-icon name="delete_table" />
            </button>
            <button class="menubar__button" @click="commands.addColumnBefore" v-tooltip.top="getTooltipOptions('Add Column Before')">
              <editor-icon name="add_col_before" />
            </button>
            <button class="menubar__button" @click="commands.addColumnAfter" v-tooltip.top="getTooltipOptions('Add Column After')">
              <editor-icon name="add_col_after" />
            </button>
            <button class="menubar__button" @click="commands.deleteColumn" v-tooltip.top="getTooltipOptions('Delete Column')">
              <editor-icon name="delete_col" />
            </button>
            <button class="menubar__button" @click="commands.addRowBefore" v-tooltip.top="getTooltipOptions('Add Row Before')">
              <editor-icon name="add_row_before" />
            </button>
            <button class="menubar__button" @click="commands.addRowAfter" v-tooltip.top="getTooltipOptions('Add Row After')">
              <editor-icon name="add_row_after" />
            </button>
            <button class="menubar__button" @click="commands.deleteRow" v-tooltip.top="getTooltipOptions('Delete Row')">
              <editor-icon name="delete_row" />
            </button>
            <button class="menubar__button" @click="commands.toggleCellMerge" v-tooltip.top="getTooltipOptions('Toggle Cell Merge')">
              <editor-icon name="combine_cells" />
            </button>
          </template>
          <template v-else>
            <button
              v-show="!isActive.paragraph()"
              class="menubar__button"
              :class="{ 'is-active': isActive.paragraph() }"
              @click="commands.paragraph"
              v-tooltip.top="getTooltipOptions('Paragraph')"
            >
              <editor-icon name="paragraph" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
              v-tooltip.top="getTooltipOptions('Heading Large<br/><em># Space</em>')"
            >
              H1
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
              v-tooltip.top="getTooltipOptions('Heading Medium<br/><em>## Space</em>')"
            >
              H2
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
              v-tooltip.top="getTooltipOptions('Heading Small<br/><em>### Space</em>')"
            >
              H3
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.todo_list() }"
              @click="commands.todo_list"
              v-tooltip.top="getTooltipOptions('Todo List<br/><em>[ ] Space</em>')"
            >
              <editor-icon name="checklist" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
              v-tooltip.top="getTooltipOptions('Bullet List<br/><em>- Space</em>')"
            >
              <editor-icon name="ul" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
              v-tooltip.top="getTooltipOptions('Number List<br/><em>1. Space</em>')"
            >
              <editor-icon name="ol" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.code_block() }"
              @click="commands.code_block"
              v-tooltip.top="getTooltipOptions('Code Block<br/><em>```</em>')"
            >
              <editor-icon name="code" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.blockquote() }"
              @click="commands.blockquote"
              v-tooltip.top="getTooltipOptions('Blockquote<br/><em>> Space</em>')"
            >
              <editor-icon name="quote" />
            </button>

            <button
              class="menubar__button"
              @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
              v-tooltip.top="getTooltipOptions('Create Table')"
            >
              <editor-icon name="table" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.image() }"
              @click="showImagePrompt(commands.image)"
              v-tooltip.top="getTooltipOptions('Insert Image')"
            >
              <editor-icon name="image" />
            </button>
          </template>
        </div>
      </editor-menu-bar>
    </div>
    <editor-content
      class="editor__content"
      v-if="editor"
      spellcheck="false"
      :editor="editor"
    />

    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <p class="pop-menu-title">Select Notes</p>
      <template v-if="hasResults">
        <div
          v-for="(item, index) in filteredItems"
          :key="item._id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedItemIndex === index }"
          @click="selectItem(item)"
        >
          {{ item.title }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">
        No items found
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import {
  Blockquote, Bold, BulletList, Code, CodeBlock,
  HardBreak, Heading, History, Image, Italic, Link, ListItem,
  OrderedList, Placeholder, Strike, Table, TableHeader,
  TableCell, TableRow, TodoList, Underline
} from 'tiptap-extensions'
import tippy, { sticky } from 'tippy.js'
import TodoItem from './TodoItem'
import NoteLink from './NoteLink'
import EditorIcon from './EditorIcon'

export default {
  name: 'Editor',
  props: {
    value: String,
    selectedNoteId: String,
    archived: Boolean
  },
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    EditorIcon
  },
  data() {
    return {
      editor: null,
      editorInFocus: false,
      emitAfterOnUpdate: false,
      mentionQuery: null,
      suggestionRange: null,
      filteredItems: [],
      navigatedItemIndex: 0,
      insertMention: () => {},
      linkUrl: null,
      linkMenuIsActive: false
    }
  },
  computed: {
    ...mapGetters('notes', { findNotesInStore: 'find' }),
    hasResults() {
      return this.filteredItems.length
    },
    showSuggestions() {
      return this.query || this.hasResults
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      editable: !this.archived,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem({ nested: true }),
        new TodoList(),
        new Bold(),
        new Strike(),
        new Code(),
        new Italic(),
        new Link(),
        new Underline(),
        new History(),
        new Image(),
        new Placeholder({
          emptyClass: 'is-empty',
          emptyNodeText: 'Write something …',
          showOnlyWhenEditable: true
        }),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new NoteLink({
          items: () => {
            return this.findNotesInStore({ query: { archived: false, _id: { $ne: this.$route.params.noteId } } }).data
          },
          onEnter: ({ items, mentionQuery, range, command, virtualNode }) => {
            this.mentionQuery = mentionQuery
            this.filteredItems = items
            this.suggestionRange = range
            this.renderPopup(virtualNode)
            this.insertMention = command
          },
          onChange: ({ items, mentionQuery, range, virtualNode }) => {
            this.mentionQuery = mentionQuery
            this.filteredItems = items
            this.suggestionRange = range
            this.navigatedItemIndex = 0
            this.renderPopup(virtualNode)
          },
          onExit: () => {
            this.mentionQuery = null
            this.filteredItems = []
            this.suggestionRange = null
            this.navigatedItemIndex = 0
            this.destroyPopup()
          },
          onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
              this.upHandler()
              return true
            }
            if (event.key === 'ArrowDown') {
              this.downHandler()
              return true
            }
            if (event.key === 'Enter') {
              this.enterHandler()
              return true
            }
            return false
          },
          onFilter: (searchItems, query) => {
            if (!query) {
              return searchItems
            }

            return searchItems
              .filter(item => item.title.toLowerCase().includes(query.toLowerCase()))
          }
        })
      ],
      onUpdate: ({ getHTML }) => {
        this.emitAfterOnUpdate = true
        this.$emit('input', getHTML())
        this.$emit('changes-made')
      },
      onFocus: () => {
        this.editorInFocus = true
      },
      onBlur: () => {
        this.editorInFocus = false
        this.$emit('editor-blur')
      }
    })
  },
  beforeDestroy() {
    this.destroyPopup()
    if (this.editor) {
      this.editor.destroy()
    }
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        // console.log('SENDER')
        this.emitAfterOnUpdate = false
        return
      }
      if (this.editor && !this.editorInFocus) {
        // console.log('RECEIVER')
        this.editor.setContent(val, false)
      }
    },
    placeholder(newValue) {
      this.editor.extensions.options.placeholder.emptyNodeText = newValue
    },
    selectedNoteId() {
      if (this.editor) {
        this.editor.setContent(this.value, false)
      }
    },
    archived: {
      handler: function () {
        if (this.editor) {
          this.editor.setOptions({ editable: !this.archived })
        }
      },
      immediate: true
    }
  },
  methods: {
    getTooltipOptions(content) {
      return { content, boundariesElement: 'body' }
    },
    showImagePrompt(command) {
      const src = prompt('Enter the url of your image here')
      if (src !== null) {
        command({ src })
      }
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    upHandler() {
      this.navigatedItemIndex = ((this.navigatedItemIndex + this.filteredItems.length) - 1) % this.filteredItems.length
    },
    downHandler() {
      this.navigatedItemIndex = (this.navigatedItemIndex + 1) % this.filteredItems.length
    },
    enterHandler() {
      const item = this.filteredItems[this.navigatedItemIndex]
      if (item) {
        this.selectItem(item)
      }
    },
    selectItem(item) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: item._id,
          label: item.title
        }
      })
      this.editor.focus()
    },
    renderPopup(node) {
      if (this.popup) {
        return
      }
      this.popup = tippy('#editor', {
        getReferenceClientRect: node.getBoundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        sticky: true,
        plugins: [sticky],
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        showOnCreate: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200]
      })
    },
    destroyPopup() {
      if (this.popup) {
        this.popup[0].destroy()
        this.popup = null
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/editor.scss';
@import '@/assets/styles/menubar.scss';
@import '@/assets/styles/menububble.scss';

</style>
