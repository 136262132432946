<template>
  <div class="hidden overflow-y-hidden mt-4 lg:flex-1 lg:flex lg:flex-col">
    <a @click.prevent="isTasksOpen=!isTasksOpen" class="flex py-1.5 ml-8 pl-0.5 mr-5" title="Toggle View">
      <h6 class="flex-1 text-xs font-bold leading-6 text-gray-600 uppercase select-none tracking-px">Unscheduled Tasks</h6>
      <span class="small-counter">{{ tasks.length }}</span>
    </a>
    <div v-if="isTasksOpen" class="overflow-y-auto overflow-x-hidden flex-1">
      <div class="flex py-0 px-5 ml-0.5 my-0">
        <draggable
          tag="div"
          class="flex-1 mt-1 mb-8"
          @change="onChange"
          :list="tasks"
          v-bind="{
            animation: 200,
            group: 'day-grid'
          }"
        >
          <div
            v-for="task in tasks" :key="task._id"
            class="bg-white rounded shadow select-none cursor-grab word-break hover:shadow-md py-2 pr-2 pl-3 mb-1.5"
            :title="task.title"
            :style="{ 'border-right': `6px solid ${task.color}` }"
          >
            <div class="flex flex-col">
              <span class="flex-1 text-sm leading-tight text-gray-700">{{ task.title }}</span>
              <span class="flex-1 text-xs text-gray-500">{{ getFolderName(task) }}</span>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  props: {
    tasks: Array
  },
  data() {
    return {
      isTasksOpen: true
    }
  },
  components: {
    draggable
  },
  computed: {
    ...mapGetters('folders', { getFolderInStore: 'get' })
  },
  methods: {
    getFolderName(task) {
      if (this.$route.params.folderId === 'all') {
        return this.getFolderInStore(task.folderId).title
      }
      return null
    },
    onChange(event) {
      if (event.added) {
        if (event.added.element.extras.type !== 'task') { return }
        if (event.added.element.extras.object.completed) { return }
        this.$emit('event-drop', { event: event.added.element, end: null })
      }
    }
  }
}
</script>
