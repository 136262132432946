<template>
  <div class="flex flex-col flex-1 px-1 pb-3 calendar-month-view">
    <div class="flex mx-1.5 mb-1 lg:mx-1">
      <div class="flex-1 mx-0.5 text-center text-gray-400 lg:mx-1" v-for="n in (weekends ? 7 : 5)" :key="n">{{ getDay(n) }}</div>
    </div>
    <div class="grid overflow-y-auto flex-1 gap-0.5 mx-2 h-full lg:gap-1" :class="[ weekends ? 'grid-cols-7': 'grid-cols-5' ]">
      <calendar-day-grid
        v-for="day in daysInView"
        :key="formatDate(day)"
        :day="day"
        :events="getDayEvents(events, day)"
        :currentView="currentView"
        :currentDate="currentDate"
      >
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
      </calendar-day-grid>
    </div>
  </div>
</template>

<script>
import { addDays, startOfISOWeek, format } from 'date-fns'
import { getDayEvents } from '../../utils'
import CalendarDayGrid from './CalendarDayGrid'

export default {
  name: 'CalendarMonthView',
  components: { CalendarDayGrid },
  props: {
    events: {
      type: Array,
      required: true
    },
    currentDate: {
      type: Date,
      required: true
    },
    currentView: {
      type: String
    },
    daysInView: {
      type: Array,
      required: true
    },
    weekends: {
      type: Boolean
    }
  },
  methods: {
    getDayEvents,
    getDay(n) {
      const startOfWeek = startOfISOWeek(new Date())
      const addNDay = addDays(startOfWeek, n - 1)
      return format(addNDay, 'EEE')
    },
    formatDate(date) {
      return format(date, 'yyyy-MM-dd')
    }
  }
}
</script>
