<template>
  <div>
    <draggable
      class="tasks-list draggable"
      :class="!enabled && 'disabled'"
      :disabled="!enabled"
      tag="div"
      @add="onAdd"
      :list="tasks"
      v-bind="{
        animation: 200,
        delay: 200,
        delayOnTouchOnly: true,
        group: 'tasks'
      }"
      >
      <transition-group tag="div" move-class="opacity-70 transition-all duration-300" :date="date">
        <task v-for="task in tasks" :key="task._id" :id="task._id" :date="task.endDate" :task="task" :draggable="true" :folders="folders" :selected-folder="selectedFolder" class="cursor-grab"></task>
      </transition-group>
    </draggable>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { hasTime, setStringWithDate } from '../utils'
import Task from '@/components/Task'

export default {
  name: 'TaskDraggable',
  components: {
    draggable, Task
  },
  props: {
    tasks: Array,
    date: String,
    selectedFolder: Object,
    folders: Array,
    enabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('tasks', { getTaskInStore: 'get' }),
    ...mapGetters('notes', { getNoteInStore: 'get' }),
    draggableTasks: {
      get() { return this.tasks },
      set(tasks) {
        // TODO:
        // console.log('set', tasks)
        // this.$store.commit('updateTaskOrder', tasks)
        // this.tasks.map((tasks, index) => {
        //   tasks.order = index + 1
        // })
      }
    }
  },
  methods: {
    onAdd(evt) {
      console.log('onAdd', evt)
      const taskId = evt.item.getAttribute('id')
      const currentEndDate = evt.item.getAttribute('date')
      const targetDate = evt.to.getAttribute('date')

      const endDateString = hasTime(currentEndDate)
        ? setStringWithDate(currentEndDate, targetDate)
        : targetDate

      if (!endDateString) return
      console.log('onAdd', endDateString)
      const taskOnStore = this.getTaskInStore(taskId)
      taskOnStore.patch({ data: { endDate: endDateString } }).then(() => {
        console.log('onAdd done')
        if (taskOnStore.noteId) {
          const note = this.getNoteInStore(taskOnStore.noteId)
          if (note) {
            const doc = new DOMParser().parseFromString(note.content, 'text/html')
            const list = doc.querySelectorAll(`[data-block-id="${taskId}"]`)
            if (list[0]) {
              list[0].setAttribute('data-end-date', endDateString)
              note.patch({ data: { content: doc.activeElement.innerHTML } })
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.tasks-list{
  &.draggable {
    min-height: 12px;
    list-style: none;
    &:empty {
      left: -16px;
      position: relative;
      & + .task-form {
        margin-top: -10px;
      }
    }
  }

  .sortable-chosen {
    background: white;
    opacity: 1;
    visibility: visible;
  }

  .sortable-ghost {
    background-color: #EEEEEE;
    opacity: 0.5;
    box-shadow: none;
    margin-right: 16px;
  }
}
</style>
