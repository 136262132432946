import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './assets/styles/index.css'
import './registerServiceWorker'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VueAnalytics from 'vue-analytics'
import { socket } from './feathers-client'
import FeatherIcon from '@/components/FeatherIcon'

const isProd = process.env.NODE_ENV === 'production'
Vue.config.productionTip = false

VTooltip.options.defaultDelay = { show: 500, hide: 100 }
VTooltip.options.popover.defaultHandleResize = false
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
Vue.component('feather-icon', FeatherIcon)

Vue.config.errorHandler = (err, vm, info) => {
  console.log('Desktivity error:', err)
  console.log('Desktivity error info:', info)
}

const connectionHandler = (bool) => {
  store.commit('setConnected', bool)
}

Vue.use(VueAnalytics, {
  id: 'UA-43457582-5',
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    socket.on('connect', () => connectionHandler(true))
    socket.on('connect_error', () => connectionHandler(false))
    socket.on('connect_failed', () => connectionHandler(false))
    socket.on('disconnect', () => connectionHandler(false))
  },
  beforeDestroy() {
    socket.off('connect', () => connectionHandler(true))
    socket.off('connect_error', () => connectionHandler(false))
    socket.off('connect_failed', () => connectionHandler(false))
    socket.off('disconnect', () => connectionHandler(false))
  }
}).$mount('#app')
