<template>
  <div class="relative pt-10 lg:pl-6 lg:pt-0 lg:pr-2 lg:pb-16">
    <template v-if="pinnedNotes.length">
      <div class="flex items-center pr-2 pb-1">
        <h3 class="flex-1 my-0 mx-4">Pinned Notes</h3>
        <button class="btn-icon-circle" title="Quick New Note" @click="createNote">
          <img src="@/assets/icons/note/icon-new-note.svg" alt="New Note" width="24" height="24"/>
        </button>
      </div>
      <div class="overflow-y-auto overflow-x-hidden h-auto">
        <div class="overflow-hidden w-full lg:w-90">
          <draggable v-model="pinnedNotesDraggable" v-bind="dragOptions">
            <note-item
              v-for="note in pinnedNotesDraggable" :key="note._id"
              :note="note" :onSelectNote="onSelectNote" :compact="settings.deskCompactPinnedNotes"
            />
          </draggable>
        </div>
      </div>
    </template>
    <template v-if="settings.deskShowRecentNotes">
      <div class="flex items-center pr-2 pb-1">
        <h3 class="flex-1 self-center my-0 mx-4">Recent Notes</h3>
        <div v-if="!haveRecentNotesLoadedOnce && !recentNotes.length" class="inline-block relative mr-1 ml-auto w-6 h-6">
          <Loading size="small"/>
        </div>
        <button v-if="!pinnedNotes.length" class="btn-icon-circle" title="Quick New Note" @click="createNote">
          <img src="@/assets/icons/note/icon-new-note.svg" alt="New Note" width="24" height="24"/>
        </button>
      </div>
      <template>
        <div v-if="recentNotes.length" class="overflow-hidden w-screen lg:w-90">
          <note-item
            v-for="note in recentNotes" :key="note._id"
            :note="note" :onSelectNote="onSelectNote" :compact="settings.deskCompactRecentNotes"
          />
        </div>
        <div v-else class="overflow-hidden w-full lg:w-90">
          <p class="py-4 px-9 text-gray-400">No notes.</p>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import draggable from 'vuedraggable'

import Folders from '@/mixins/Folders'
import Settings from '@/mixins/Settings'

import NoteItem from '@/components/NoteItem'
import Loading from '@/components/Loading'

export default {
  name: 'DeskNotes',
  components: { draggable, NoteItem, Loading },
  mixins: [
    Folders,
    Settings,
    makeFindMixin({ service: 'notes', name: 'pinnedNotes', watch: true }),
    makeFindMixin({ service: 'notes', name: 'recentNotes', watch: true })
  ],
  computed: {
    ...mapGetters(['connected']),
    dragOptions() {
      return {
        animation: 500,
        delay: 100,
        delayOnTouchOnly: true
      }
    },
    pinnedNotesDraggable: {
      get() {
        return this.pinnedNotes
      },
      set(notes) {
        notes.forEach((note, index) => {
          note.pinnedOrder = index
          note.patch()
        })
      }
    },
    pinnedNotesParams() {
      return {
        query: {
          pinned: true,
          $sort: { pinnedOrder: 1 }
        },
        temps: !this.connected
      }
    },
    pinnedNotesQueryWhen() { return this.connected },
    recentNotesParams() {
      return {
        query: {
          archived: false,
          pinned: { $ne: true },
          $limit: 3,
          $sort: { updatedAt: -1 }
        },
        temps: !this.connected
      }
    },
    recentNotesQueryWhen() { return this.connected }
  },
  methods: {
    onSelectNote(note) {
      this.$router.push({ path: `/app/notes/${note.folderId}/${note._id}` })
    },
    createNote() {
      const { Note } = this.$FeathersVuex.api
      const note = new Note({
        title: 'Untitled Note',
        content: '',
        folderId: this.folders[0]._id
      })
      note.save().then(createdNote => {
        this.$store.commit('setSelectedNoteId', createdNote._id)
        this.$router.push({ path: `/app/notes/${createdNote.folderId}/${createdNote._id}` })
      })
    }
  }
}
</script>
