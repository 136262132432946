<template>
  <div class="relative cursor-pointer hover:bg-blue-100 lg:flex lg:flex-col"
    :class="[{ 'bg-blue-50': isToday }, isMonthView ? 'rounded': 'min-h-120 lg:min-h-full rounded-lg mb-2', isInMonth ? 'bg-gray-100' : 'bg-gray-50' ]"
    @click="onDateClick"
  >
    <div class="flex px-3 w-full" v-if="isMonthView">
      <div class="flex-1 text-sm font-semibold leading-8 select-none" :class="[ isToday ? 'text-blue-600' : isInMonth ? 'text-gray-600' : 'text-gray-300' ]">{{ getNumeric }}</div>
      <div class="text-xs leading-8 text-gray-400" v-if="events.length > 0">{{ getItemsNumber }}</div>
    </div>
    <div class="flex px-3 w-full" v-else>
      <div class="flex-1 text-sm font-semibold leading-8 select-none lg:leading-10 lg:h-10 lg:text-base" :class="[ isToday ? 'text-blue-600' : 'text-gray-600' ]">{{ getNumeric }}</div>
      <div class="text-sm leading-8 text-gray-400 lg:leading-10" :class="[ isToday ? 'text-blue-600' : 'text-gray-300' ]">{{ getDay }}</div>
    </div>
    <draggable
      tag="div"
      class="top-8 lg:absolute lg:left-0 lg:right-0 lg:bottom-0 lg:overflow-y-auto lg:overflow-x-hidden lg:px-2 lg:flex"
      :class="[ isMonthView ? 'px-0.5 pb-3' : 'px-2 pb-1 lg:px-3 lg:py-0 lg:top-10' ]"
      @change="onChange"
      :list="sortItems(events)"
      v-bind="{
        animation: 200,
        delay: 200,
        delayOnTouchOnly: true,
        group: 'day-grid',
        disabled: false
      }"
    >
      <transition-group
        tag="div"
        class="lg:w-full lg:h-full"
        move-class="transition-all duration-300"
      >
        <div
          v-for="event in sortItems(events)"
          class="bg-white rounded shadow select-none cursor-grab word-break hover:shadow-md"
          :class="[ eventClasses(event), isMonthView ? 'overflow-hidden text-xs leading-5 mb-1 py-0.5 pr-0 pl-2' : 'text-sm py-1.5 px-2 mb-2 lg:leading-tight lg:py-2 lg:pr-2 lg:pl-3 lg:mb-1.5' ]"
          :key="event.index"
          :style="eventStyle(event)"
          @click.stop="onEventClick(event)"
        >
          <slot
            name="event"
            :event="event"
            :getFolderName="getFolderName"
            :translateDate="translateDate"
            :isMonthView="isMonthView"
          >
            <div class="flex">
              <span class="flex-1" :class="{ 'overflow-hidden whitespace-nowrap overflow-clip': isMonthView }">{{ event.title }}</span>
            </div>
            <div v-if="!isMonthView" class="block mt-1 text-xs text-blue-600">
              {{ translateDate(event) }}
            </div>
          </slot>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addMinutes, format, formatISO, isDate, isSameDay, isSameMonth, parseISO, startOfHour } from 'date-fns'
import { sortBy } from 'lodash-es'
import draggable from 'vuedraggable'
import CalendarEventBus from './CalendarEventBus'
import { hasTime } from '../../utils'

export default {
  name: 'CalendarDayGrid',
  components: {
    draggable
  },
  props: {
    day: Date,
    events: Array,
    currentView: String,
    currentDate: Date
  },
  data() {
    return {
      clicks: 0,
      timer: null
    }
  },
  computed: {
    ...mapGetters('folders', { getFolderInStore: 'get' }),
    isMonthView() {
      return this.currentView === 'month'
    },
    getDay() {
      return format(this.day, 'eee')
    },
    getNumeric() {
      return format(this.day, 'd')
    },
    getItemsNumber() {
      return this.events.length + (this.events.length === 1 ? ' item' : ' items')
    },
    isToday() {
      return isSameDay(this.day, new Date())
    },
    isInMonth() {
      return isSameMonth(this.day, this.currentDate)
    }
  },
  methods: {
    onEventClick(event) {
      CalendarEventBus.$emit('event-click', { event: event })
    },
    onDateClick() {
      this.clicks++
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          CalendarEventBus.$emit('date-click', {
            start: formatISO(addMinutes(startOfHour(new Date()), 60)),
            end: formatISO(addMinutes(startOfHour(new Date()), 90)),
            allDay: false
          })
          this.clicks = 0
        }, 500)
      } else {
        clearTimeout(this.timer)
        CalendarEventBus.$emit('date-double-click', this.day)
        this.clicks = 0
      }
    },
    onChange(event) {
      if (event.added) {
        if (event.added.element.repeat) { return }
        CalendarEventBus.$emit('event-drop', { event: event.added.element, end: format(this.day, 'yyyy-MM-dd') })
      }
    },
    eventClasses(event) {
      if (event.extras?.type === 'task') {
        return `p${event.extras.object.priority}`
      }
    },
    eventStyle(event) {
      if (event.color) {
        if (event.extras?.type === 'task') {
          if (event.extras.object.completed) {
            return { color: 'rgba(0,0,0,0.2)', textDecoration: 'line-through' }
          }
        }
        // if (event.extras?.type === 'note') {
        //   return { borderRight: '6px solid #747D93' }
        // }
      }
      return {}
    },
    // multiDay(event) {
    //   const { start, end } = event
    //   if (!start && !end) { return }
    //   if (!start && end) { return }
    //   if (start && end) {
    //     if (isSameDay(start, end)) { return }
    //     if (differenceInCalendarDays(end, start) > 0) {
    //       return (differenceInCalendarDays(this.day, start) + 1) + '&frasl;' + (differenceInCalendarDays(end, start) + 1)
    //     }
    //   }
    // },
    getFolderName(event) {
      if (this.$route.params.folderId === 'all' && !this.isMonthView) {
        return this.getFolderInStore(event.folderId).title
      }
      return null
    },
    translateDate(event) {
      const { start, end, allDay } = event
      // console.log('translate', event._id, event.extras)
      if (!start && !end) { return }
      if (event.extras.object?.completed) { return }
      if (start && end) {
        // Duration
        if (isSameDay(parseISO(start), parseISO(end))) {
          if (allDay) { return }
          return format(parseISO(start), 'HH:mm') + ' – ' + format(parseISO(end), 'HH:mm')
        }
        return format(parseISO(start), 'MMM dd') + ' – ' + format(parseISO(end), 'MMM dd')
      }

      if (end) {
        // Due date
        if (isDate(parseISO(end))) {
          if (format(parseISO(end), 'HH:mm') !== '00:00') {
            return format(parseISO(end), 'HH:mm')
          }
        }
      }
    },
    sortItems(arr) {
      return sortBy(arr, [
        a => a.extras.object?.completed ? 1 : -1,
        a => -hasTime(a.end),
        a => parseISO(a.end),
        a => a.extras.type === 'note' ? 1 : -1,
        a => a.extras.type === 'task',
        'extras.object.priority'
      ])
    }
  }
}
</script>
