<template>
  <svg
    class="inline-block relative w-5 h-5 align-middle lg:w-4 lg:h-4"
    fill="currentColor"
  >
    <use :xlink:href="require('@/assets/icons/editor-icon-sprite.svg') + `#${name}`"/>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>
