<template>
  <transition appear name="modal">
    <div class="absolute top-0 left-0 z-50 w-full h-full bg-black bg-opacity-40 transition-all duration-300" @mousedown="$emit('close')">
      <div class="p-3">
        <div
          class="flex flex-col mx-auto mt-36 mb-auto w-full max-h-screen bg-white rounded-xl shadow-lg transition-all duration-300 modal-container"
          :style="`max-width: ${containerWidth}px; min-height: ${minHeight}px`"
          @mousedown.stop
        >
          <slot name="tab"></slot>
          <div class="mx-6 mt-6">
            <slot name="header"></slot>
          </div>
          <div class="mx-6 mb-8">
            <slot name="body"></slot>
          </div>
          <div class="flex overflow-auto mx-6 mt-auto mb-6">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    containerWidth: {
      type: Number,
      default: 600
    },
    minHeight: {
      type: Number,
      default: 400
    }
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.$emit('close')
      }
    }
    document.addEventListener('keydown', escapeHandler)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  }
}
</script>

<style lang="scss">
.modal-enter, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active, .modal-leave-active {
  transition: opacity .15s ease-in;
}

.modal-enter .modal-container, .modal-leave-active .modal-container {
  transform: translate3d(0, 100%, 0);
}
</style>
