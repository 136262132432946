<template>
  <span
    class="block cursor-text focus:outline-none"
    :placeholder="placeholder"
    :contenteditable="(content && hasFocus) && !readonly"
    spellcheck="false"
    @mousedown="onMouseDown"
    @blur="onBlur"
    @keydown.enter.prevent="$event.target.blur"
    @keydown.esc.prevent="$event.target.blur"
  >
  </span>
</template>

<script>
export default {
  name: 'Editable',
  props: {
    content: {
      type: String,
      default: ''
    },
    placeholder: String,
    allowEmpty: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    linkify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasFocus: false
    }
  },
  mounted() {
    this.$el.innerHTML = this.parseLink(this.content)
  },
  watch: {
    content(val) {
      this.$el.textContent = val
    }
  },
  methods: {
    focus() {
      this.$el.focus()
    },
    parseLink(str) {
      if (this.linkify) {
        return str.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[\da-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=,]*))/gi, "<a href='$1' target='_blank' rel='noopener noreferrer nofollow'>$1</a>")
      }
      return str
    },
    onMouseDown(event) {
      if (event.target instanceof HTMLAnchorElement) {
        event.preventDefault()
      } else if (!this.hasFocus) {
        this.hasFocus = true
        event.preventDefault()
        this.$el.textContent = this.content
        this.$nextTick(() => {
          const range = document.createRange()
          const selection = window.getSelection()
          const node = this.$el.childNodes[0] ?? this.$el
          range.setStart(node, this.$el.textContent.length)
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
        })
      }
    },
    onBlur(event) {
      if (event.target.textContent.length > 0 && event.target.textContent !== this.content) {
        this.$emit('update', event.target.textContent.trim())
      } else if (this.allowEmpty) {
        this.$emit('update', event.target.textContent.trim())
      } else {
        this.$el.textContent = this.content
      }
      this.$el.innerHTML = this.parseLink(event.target.textContent.trim())
      this.hasFocus = false
    }
  }
}
</script>
