<template>
  <div class="flex overflow-y-auto flex-col px-1 lg:flex-1">
    <div class="overflow-y-auto flex-1 mx-2 lg:grid lg:h-full lg:gap-2 lg:overflow-y-hidden lg:mb-2" :class="[ weekends ? 'lg:grid-cols-7': 'lg:grid-cols-5' ]">
      <calendar-day-grid
        v-for="day in daysInView"
        :key="formatDate(day)"
        :day="day"
        :events="getDayEvents(events, day)"
        :currentView="currentView"
        :currentDate="currentDate"
      >
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
      </calendar-day-grid>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import CalendarDayGrid from './CalendarDayGrid'
import { getDayEvents } from '../../utils'

export default {
  name: 'CalendarWeekView',
  components: { CalendarDayGrid },
  props: {
    events: {
      type: Array,
      required: true
    },
    currentDate: {
      type: Date,
      required: true
    },
    currentView: {
      type: String
    },
    daysInView: {
      type: Array,
      required: true
    },
    weekends: {
      type: Boolean
    }
  },
  methods: {
    getDayEvents,
    formatDate(date) {
      return format(date, 'yyyy-MM-dd')
    }
  }
}
</script>
