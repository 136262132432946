<template>
  <div class="flex absolute top-0 left-0 justify-center items-center w-full h-full">
    <div class="loader" :class="[color, size]"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    color: {
      default: 'black',
      type: String
    },
    size: {
      default: 'normal',
      type: String
    }
  }
}
</script>

<style lang="scss">
.loader {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  border-right: 3px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  border-left: 3px solid black;
  transform: translateZ(0);
  animation: load8 1.5s infinite linear;

  &:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.small {
    width: 20px;
    height: 20px;

    &:after {
      width: 20px;
      height: 20px;
    }
  }

  &.white {
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 3px solid white;
  }

  &.small {
    border-width: 2px;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
