<template>
  <draggable class="folders-list draggable" tag="div"
    v-model="draggableFolders"
    v-bind="{
      handle: '.folder-drag-handle',
      animation: 200,
      group: 'folders'
    }"
  >
    <div
      v-for="folder in draggableFolders"
      :key="folder._id"
    >
      <folder-editable
        :folder="folder"
        :link="link"
        :count="count(folder._id)"
        :draggable="true"
      />
    </div>
  </draggable>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import FolderEditable from '@/components/FolderEditable'

export default {
  name: 'FoldersDraggable',
  components: {
    draggable, FolderEditable
  },
  props: {
    folders: Array,
    link: String,
    count: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['connected']),
    ...mapGetters('folders', { getFolderInStore: 'get' }),
    draggableFolders: {
      get() {
        return [...this.folders].sort((a, b) => a.order - b.order)
      },
      set(folders) {
        folders.map((f, i) => {
          const folder = this.getFolderInStore(f._id)
          folder.order = i
          folder.patch()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.folders-list {
  &.draggable {
    list-style: none;
    &:empty {
      left: -16px;
      position: relative;
    }
  }
  .dropdown + ul {
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
  }
  .dropdown-open + ul {
    max-height: 100%;
  }
  .folders-sub {
    margin-left: 1rem;
    min-height: 4px;
  }
  .sortable-chosen {
    opacity: 1;
    visibility: visible;
  }
  .sortable-ghost {
    box-shadow: none;
    opacity: 0.5;
    a {
      background-color: #EEEEEE;
    }
  }
}
</style>
