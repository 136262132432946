import { Mention } from 'tiptap-extensions'
import NoteLinkComponent from './NoteLinkComponent.vue'
import store from '../../store/store'

export default class NoteLink extends Mention {
  get name() {
    return 'note_link'
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '@',
        allowSpaces: true,
        startOfLine: false
      },
      mentionClass: 'note-link',
      suggestionClass: 'note-link-suggestion'
    }
  }

  get schema() {
    return {
      attrs: {
        id: {}
      },
      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
      toDOM: node => [
        'span',
        {
          class: this.options.mentionClass,
          'data-note-id': node.attrs.id
        },
        store.getters['notes/find']({ query: { _id: node.attrs.id } }).data[0].title
      ],
      parseDOM: [
        {
          tag: 'span[data-note-id]',
          getAttrs: dom => {
            const id = dom.getAttribute('data-note-id')
            return { id }
          }
        }
      ]
    }
  }

  get view() {
    return NoteLinkComponent
  }
}
