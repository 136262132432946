<template>
  <div class="flex overflow-y-hidden flex-col h-full">
    <div class="flex flex-shrink-0 items-center h-12 lg:pl-3 lg:h-14">
      <div class="block ml-2 lg:hidden">
        <a @click.prevent="$store.commit('toggleSidebar')" class="btn-icon-circle" title="Menu">
          <img src="@/assets/icons/icon-nav-menu.svg"/>
        </a>
      </div>
      <h1 class="flex-grow pl-2 m-0 text-xl font-semibold leading-10 whitespace-nowrap tracking-xl overflow-ellipsis lg:leading-7 lg:pl-3 lg:text-2xl lg:tracking-2xl">{{ viewTitle }}</h1>
      <div class="flex items-center">
        <button v-if="!isTodayInView" class="mr-2 text-sm font-medium text-gray-600 bg-transparent btn hover:bg-gray-50 lg:text-base" title="Press T" @click="today">Today</button>
        <button class="mr-1 btn-icon-circle" id="prev" title="Press Arrow Left" @click.prevent="prev">
          <feather-icon name="chevron-left"/>
        </button>
        <button class="mr-3 btn-icon-circle" id="next" title="Press Arrow Right" @click.prevent="next">
          <feather-icon name="chevron-right"/>
        </button>
      </div>
      <div class="hidden mr-3 toggle lg:block">
        <div
          class="btn" title="Press W"
          :class="{ 'active': currentView === 'week' }"
          @click.prevent="setCurrentView('week')"
        >
          Week
        </div>
        <div
          class="btn" title="Press M"
          :class="{ 'active': currentView === 'month' }"
          @click.prevent="setCurrentView('month')"
        >
          Month
        </div>
      </div>
    </div>
    <component
      :is="`calendar-${currentView}-view`"
      :events="mergedEvents"
      :currentDate="currentDate"
      :currentView="currentView"
      :daysInView="daysInView"
      :weekends="weekends"
    >
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </component>
  </div>
</template>

<script>
import { isSameMonth, format, startOfMonth, startOfISOWeek, endOfMonth, endOfISOWeek, isWithinInterval, isAfter, isBefore, addMonths, subMonths, subDays, addDays, intervalToDuration, addMinutes, parseISO, endOfDay, startOfDay } from 'date-fns'
import { RRule } from 'rrule'
import CalendarEventBus from './CalendarEventBus'
import CalendarMonthView from './CalendarMonthView'
import CalendarWeekView from './CalendarWeekView'
import CalendarDayGrid from './CalendarDayGrid'
import { dateToFullString, dateToShortString, hasTime } from '../../utils'

export default {
  name: 'CalendarView',
  components: {
    'calendar-month-view': CalendarMonthView,
    'calendar-week-view': CalendarWeekView,
    CalendarDayGrid
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    initialView: {
      type: String,
      default: 'week',
      validator(value) {
        return [
          'week',
          'month'
        ].indexOf(value) !== -1
      }
    },
    weekends: {
      type: Boolean,
      default: true
    },
    events: {
      type: Array,
      required: true
    },
    dateClick: {
      type: Function
    },
    select: {
      type: Function
    },
    eventClick: {
      type: Function
    },
    eventResize: {
      type: Function
    },
    eventDrop: {
      type: Function
    },
    viewDidMount: {
      type: Function
    }
  },
  data() {
    return {
      now: new Date(),
      timer: null,
      currentView: this.initialView,
      currentDate: new Date(),
      eventHandlers: [
        'event-click',
        'event-drop',
        'date-click'
      ]
    }
  },
  computed: {
    viewTitle() {
      if (isSameMonth(this.currentStart, this.currentEnd)) {
        return format(this.currentDate, 'MMMM yyyy')
      } else {
        return format(this.currentStart, 'MMM') + ' - ' + format(this.currentEnd, 'MMM yyyy')
      }
    },
    getCurrentViewName() {
      return this.currentView[0].toUpperCase() + this.currentView.slice(1)
    },
    isMonthView() {
      return this.currentView === 'month'
    },
    currentStart() {
      return this.isMonthView
        ? startOfMonth(this.currentDate)
        : startOfISOWeek(this.currentDate)
    },
    currentEnd() {
      return this.isMonthView
        ? endOfMonth(this.currentDate)
        : endOfISOWeek(this.currentDate)
    },
    isTodayInView() {
      return isWithinInterval(new Date(), { start: this.currentStart, end: this.currentEnd })
    },
    daysInView() {
      const days = []
      let day = this.currentStart

      const pushDay = (start) => {
        return this.weekends || (start.getDay() !== 0 && start.getDay() !== 6)
      }

      if (this.isMonthView) {
        let start = startOfISOWeek(this.currentStart)
        if (isAfter(this.currentStart, start)) {
          while (start < this.currentStart) {
            if (pushDay(start)) {
              days.push(start)
            }
            start = addDays(start, 1)
          }
        }
      }

      while (day <= this.currentEnd) {
        if (pushDay(day)) {
          days.push(day)
        }
        day = addDays(day, 1)
      }

      if (this.isMonthView) {
        let start = addDays(this.currentEnd, 1)
        const end = endOfISOWeek(this.currentEnd)
        if (isBefore(this.currentEnd, end)) {
          while (start <= end) {
            if (pushDay(start)) {
              days.push(start)
            }
            start = addDays(start, 1)
          }
        }
      }

      return days
    },
    mergedEvents() {
      const newEvents = []
      this.events.map(ev => {
        if (ev.rruleString?.length) {
          const duration = intervalToDuration({ start: parseISO(ev.start), end: parseISO(ev.end) })
          const options = RRule.parseString(ev.rruleString)
          options.dtstart = hasTime(ev.start) ? parseISO(ev.start) : parseISO(ev.start + 'T00:00:00Z')
          const rule = new RRule(options)
          const betweenStart = startOfDay(this.daysInView[0])
          const betweenEnd = endOfDay(this.daysInView[this.daysInView.length - 1])
          const repeat = rule.between(betweenStart, betweenEnd)
          // console.log('rule:', rule.toString())
          // console.log('betweenStart:', betweenStart)
          // console.log('betweenEnd:', betweenEnd)
          // console.log('repeat:', repeat)
          repeat.map((time, ti) => {
            const start = hasTime(ev.start) ? dateToFullString(time) : dateToShortString(time)
            if (ti > 0 || ev.start !== start) {
              newEvents.push({
                ...ev,
                index: ev._id + '-' + ti,
                repeat: true,
                repeatStart: start,
                repeatEnd: hasTime(ev.start)
                  ? dateToFullString(addMinutes(time, duration.minutes))
                  : dateToShortString(addDays(time, duration.days))
              })
            }
          })
        }
      })
      return this.events.concat(newEvents).map(e => ({ ...e, index: e._id }))
    }
  },
  methods: {
    createTimer() {
      this.timer = setInterval(() => {
        this.now = new Date()
      }, 1000 * 60)
    },
    setCurrentView(view) {
      if (view && view !== this.currentView) {
        this.currentView = view
        this.$emit('view-changed', view)
      }
    },
    goToDate(date) {
      this.currentDate = date
    },
    today() {
      this.currentDate = new Date()
    },
    prev() {
      if (this.isMonthView) {
        this.currentDate = subMonths(this.currentDate, 1)
      } else {
        this.currentDate = subDays(this.currentDate, 7)
      }
    },
    next() {
      if (this.isMonthView) {
        this.currentDate = addMonths(this.currentDate, 1)
      } else {
        this.currentDate = addDays(this.currentDate, 7)
      }
    },
    handleDateDoubleClick() {
      const listener = (payload) => {
        if (this.isMonthView) {
          this.setCurrentView('week')
          this.goToDate(payload)
        }
      }
      CalendarEventBus.$on('date-double-click', listener)
      this.$once('hook:beforeDestroy', () => {
        CalendarEventBus.$off('date-double-click', listener)
      })
    },
    registerEventBus(name) {
      const listener = (payload) => {
        this.$emit(name, payload)
      }
      CalendarEventBus.$on(name, listener)
      this.$once('hook:beforeDestroy', () => {
        CalendarEventBus.$off(name, listener)
      })
    }
  },
  mounted() {
    this.eventHandlers.forEach(name => this.registerEventBus(name))
    this.handleDateDoubleClick()
    this.$emit('view-did-mount')
  },
  created() {
    this.createTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
