<template>
  <li class="flex relative list-none rounded-xl group hover:bg-gray-50"
    :data-block-id="node.attrs.blockId"
    :data-type="node.type.name"
    :data-done="taskCompleted.toString()"
    :data-priority="node.attrs.priority"
  >
    <div class="flex absolute -left-4 items-center w-4 h-7 rounded-sm opacity-100 pointer-events-auto select-none lg:h-10 cursor-grab lg:opacity-0 group-hover:opacity-100" data-drag-handle contenteditable="false">
      <svg viewBox="0 0 10 10" class="block flex-shrink-0 w-4 h-4 text-gray-300 fill-current dragHandle"><path d="M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z"></path></svg>
    </div>
    <div class="flex justify-center items-center self-start mr-1 w-10 h-7 select-none lg:h-10">
      <task-checkbox
        contenteditable="false"
        :value="taskCompleted"
        :priority="parseInt(node.attrs.priority)"
        @input="onClickCheckbox"
      />
    </div>
    <div class="flex flex-col flex-1">
      <div class="mb-0 leading-7 lg:leading-10" :class="{ 'line-through text-gray-400': taskCompleted }" ref="content" :contenteditable="view.editable.toString()"></div>
      <span class="self-start p-0 mt-1 text-xs leading-none text-right text-gray-600 whitespace-normal bg-transparent select-none lg:hidden" v-if="taskExistInStore">{{ taskEndDate }}</span>
    </div>
    <div class="flex items-baseline" contenteditable="false">
      <span class="self-start py-0 pr-0.5 pl-2 text-xs leading-10 text-right text-gray-600 whitespace-normal bg-transparent select-none hidden lg:inline-block" v-if="taskExistInStore">{{ taskEndDate }}</span>
      <task-control
        class="h-7 lg:h-10"
        v-if="taskExistInStore"
        :task="taskInStore"
        :deleteTask="deleteTask"
        :setPriority="setPriority"
      />
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import ObjectID from 'bson-objectid'
import { differenceInYears, format, subDays } from 'date-fns'
import { hasTime } from '../../utils'
import TaskControl from '@/components/TaskControl'
import TaskCheckbox from '@/components/TaskCheckbox'

export default {
  name: 'TodoItemComponent',
  components: {
    TaskCheckbox,
    TaskControl
  },
  props: ['node', 'updateAttrs', 'view', 'getPos'],
  data() {
    return {
      disabledDates: {
        to: subDays(new Date(), 1)
      },
      isSaving: false,
      editDate: false
    }
  },
  computed: {
    ...mapGetters('notes', {
      getNoteInStore: 'get'
    }),
    ...mapGetters('tasks', {
      getTaskInStore: 'get',
      isTaskPendingById: 'isPendingById'
    }),
    ...mapGetters(['connected']),
    taskEndDate() {
      let formatStr = 'dd MMM'
      if (this.taskInStore?.endDate && differenceInYears(new Date(this.taskInStore.endDate), new Date()) < 0) {
        formatStr += ' yyyy'
      }
      return this.taskInStore?.endDate
        ? hasTime(this.taskInStore.endDate)
          ? format(new Date(this.taskInStore.endDate), formatStr + ', HH:mm')
          : format(new Date(this.taskInStore.endDate), formatStr)
        : null
    },
    selectedNoteId() {
      return this.$store.state.selectedNoteId
    },
    noteInStore() {
      return this.getNoteInStore(this.selectedNoteId)
    },
    folderId() {
      return this.noteInStore.folderId
    },
    taskInStore() {
      return this.getTaskInStore(this.node.attrs.blockId)
    },
    taskExistInStore() {
      return this.taskInStore !== null
    },
    taskPending() {
      return this.isTaskPendingById(this.node.attrs.blockId)
    },
    taskCompleted() {
      return this.taskInStore ? this.taskInStore.completed : this.node.attrs.done
    },
    priorityClass() {
      return 'p' + this.node.attrs.priority
    }
  },
  watch: {
    'noteInStore.taskSync'(val) {
      if (val) {
        this.saveToTask()
      }
    }
  },
  mounted() {
    if (this.node.attrs.blockId === '') {
      this.updateAttrs({
        blockId: ObjectID().toString()
      })
    }
    this.$nextTick(() => {
      if (this.noteInStore.taskSync !== false && !this.taskExistInStore &&
        this.$refs.content?.innerText.trim().length > 0) {
        this.isSaving = true
        this.$store.dispatch('tasks/get', this.node.attrs.blockId)
          .then(() => { this.isSaving = false })
          .catch(err => {
            console.log(err.message)
            this.saveToTask()
          })
      }
    })
  },
  updated() {
    if (this.noteInStore.taskSync !== false && !this.isSaving &&
    ((this.taskExistInStore && this.taskInStore.title !== this.$refs.content.innerText) ||
    (!this.taskExistInStore))) {
      this.saveToTask()
    }
  },
  methods: {
    saveToTask() {
      if (this.noteInStore.taskSync === false) return
      this.$nextTick(() => {
        if (this.$refs.content && this.$refs.content.innerText.trim().length > 0) {
          this.isSaving = true
          const taskObj = {
            _id: this.node.attrs.blockId,
            title: this.$refs.content.innerText.replace(/(<br >|\r\n|\n|\n\n|\r)/gm, ''),
            completed: this.node.attrs.done,
            noteId: this.selectedNoteId,
            folderId: this.folderId,
            priority: parseInt(this.node.attrs.priority)
          }
          const { Task } = this.$FeathersVuex.api
          if (this.taskExistInStore && !this.taskPending) {
            const task = new Task(taskObj)
            task.patch()
              .then(() => {
                this.isSaving = false
                // console.log('success patch task:', this.$refs.content.innerText.trim())
              })
              .catch((e) => console.log('error patch task:', e.code, e.message))
          } else if (!this.taskExistInStore) {
            const task = new Task(taskObj)
            task.create()
              .then(() => {
                this.isSaving = false
                // console.log('success create task:', this.$refs.content.innerText.trim())
              })
              .catch((e) => console.log('error create task:', e.code, e.message))
          }
        }
      })
    },
    onClickCheckbox(val) {
      this.updateAttrs({ done: val })
      this.taskInStore?.patch({ data: { completed: val } })
    },
    setPriority(num) {
      this.updateAttrs({ priority: num })
      this.taskInStore?.patch({ data: { priority: num } })
    },
    // TODO: onDatepickerBlur https://github.com/charliekassel/vuejs-datepicker/issues/493
    deleteNode() {
      // make a prosemirror transaction
      // which available on editor node
      const tr = this.view.state.tr
      const pos = this.getPos()
      tr.delete(pos, pos + this.node.nodeSize)
      this.view.dispatch(tr)
    },
    deleteTask() {
      try {
        this.taskInStore.remove()
        this.deleteNode()
      } catch {
        this.deleteNode()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';

.editor__content .ProseMirror ul[data-type=todo_list] {
  position: relative;
  padding-left: 12px;
  left: -12px;
  margin-right: -12px;
  @media #{$desktop} {
    padding-left: 24px;
    left: -36px;
    margin-right: -36px;
  }
}

</style>
