<template>
  <router-link contenteditable="false" class="note-link" :to="`/app/notes/all/${node.attrs.id}`">
    <img src="@/assets/icons/icon-nav-note.svg" alt="Note"/>
    <span title="Open Note">{{ note.title }}</span>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NoteLink',
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('notes', { findNotesInStore: 'find' }),
    note() {
      return this.findNotesInStore({ query: { _id: this.node.attrs.id } }).data[0]
    }
  }
}
</script>
