<template>
  <div class="mr-1 w-10 cursor-pointer opacity-1 lg:opacity-0 group-hover:opacity-100">
    <v-popover class="h-7 lg:h-10" @apply-show="taskMenuOnShow" @apply-hide="taskMenuOnHide">
      <div class="flex justify-center items-center w-10 h-7 lg:h-10">
        <feather-icon name="more-horizontal" size="20" class="text-gray-400"/>
      </div>
      <template slot="popover">
        <div class="pop-menu" v-if="isTaskMenuOpen">
          <div class="pt-2">
            <button class="pop-menu-item schedule-item" v-close-popover @click="setEndDate(getDate(0))" title="Today">
              <div class="schedule-item-icon">
                <img src="../assets/icons/task/icon-date-today.svg" alt="Today" width="24"/>
              </div>
              <div class="schedule-item-label">Today</div>
            </button>
            <button class="pop-menu-item schedule-item" v-close-popover @click="setEndDate(getDate(1))" title="Tomorrow">
              <div class="schedule-item-icon">
                <img src="../assets/icons/task/icon-date-tomorrow.svg" alt="Tomorrow" width="24"/>
              </div>
              <div class="schedule-item-label">Tomorrow</div>
            </button>
            <button class="pop-menu-item schedule-item" v-close-popover @click="setEndDate(null)" title="No Due Date">
              <div class="schedule-item-icon">
                <img src="../assets/icons/task/icon-date-none.svg" alt="No Due Date" width="24"/>
              </div>
              <div class="schedule-item-label">No Due Date</div>
            </button>
            <div class="pop-menu-divider"></div>
            <div class="schedule-item">
              <div
                class="schedule-item-icon pointer"
                @click="openEndDatePicker"
              >
                <img src="../assets/icons/task/icon-date-pick.svg" alt="No Due Date" width="24"/>
              </div>
              <div class="schedule-item-label">
                <v-date-picker ref="endDatePicker"
                  v-model="selectedEndDate"
                  :attributes="attrs"
                  :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                  :masks="{ input: ['DD MMM YYYY'], weekdays: 'WWW'}"
                  :popover="{visibility: 'click'}"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="datepicker"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Pick Date"
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="schedule-item">
              <div class="schedule-item-icon">
                <feather-icon name="clock" size="20" thin/>
              </div>
              <div
                class="schedule-item-label"
                v-show="!showEndTimePicker"
                @click.prevent="toggleEndTimePicker"
              >
                + Add Time
              </div>
              <timepicker ref="timepicker" class="ml-0" v-show="showEndTimePicker" v-model="selectedEndTime"/>
              <div
                class="py-0 px-2 ml-auto text-xs leading-8 text-gray-600 schedule-item-label"
                v-show="showEndTimePicker"
                @click.prevent="toggleEndTimePicker"
              >
                {{ selectedEndTime ? 'Clear' : 'Close' }}
              </div>
            </div>
          </div>
          <div class="pop-menu-divider"></div>
          <div class="pb-3 pop-menu-title">Priority</div>
          <div class="flex mt-0 mb-2 ml-3">
            <a class="mr-1 rounded-sm hover:bg-gray-50" v-close-popover @click="setPriority(1)" title="High Priority"><img src="../assets/icons/task/icon-priority-high.svg" alt="High Priority" width="40" height="40"/></a>
            <a class="mr-1 rounded-sm hover:bg-gray-50" v-close-popover @click="setPriority(2)" title="Medium Priority"><img src="../assets/icons/task/icon-priority-medium.svg" alt="Medium Priority" width="40" height="40"/></a>
            <a class="mr-1 rounded-sm hover:bg-gray-50" v-close-popover @click="setPriority(3)" title="Low Priority"><img src="../assets/icons/task/icon-priority-low.svg" alt="Low Priority" width="40" height="40"/></a>
            <a class="mr-1 rounded-sm hover:bg-gray-50" v-close-popover @click="setPriority(4)" title="No Priority"><img src="../assets/icons/task/icon-priority-none.svg" alt="No Priority" width="40" height="40"/></a>
          </div>
          <div class="pop-menu-divider" v-if="folders && taskFolder"></div>
          <div class="pop-menu-item" v-if="folders && taskFolder">
            <v-popover>
              <a>Move to Folder</a>
              <template slot="popover">
                <div>
                  <ul class="pb-2 folder-option">
                    <li>Folders</li>
                    <li v-for="folder in folders" :key="folder._id" @click="setFolder(folder._id)" v-close-popover :class="{ 'selected': folder._id === taskFolder._id }">
                      <span>{{ folder.title }}</span>
                    </li>
                  </ul>
                </div>
              </template>
            </v-popover>
          </div>
          <div class="pop-menu-divider"></div>
          <div class="pop-menu-item">
            <a class="inline-block pb-1 mr-2 text-sm leading-5 text-red-600 whitespace-nowrap bg-red-50" v-close-popover v-if="task.__isTemp">Pending</a>
            <a v-else v-close-popover @click.prevent="onClickDelete">Delete</a>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Timepicker from './Timepicker'
import { hasTime, toHourMinute, setStringWithDate, setStringWithTime } from '../utils'
import { format, addDays, parseISO, subDays } from 'date-fns'

export default {
  name: 'TaskControl',
  components: {
    Timepicker,
    'v-date-picker': DatePicker
  },
  props: {
    task: { type: Object },
    taskFolder: { type: Object },
    folders: { type: Array },
    deleteTask: { type: Function },
    setPriority: { type: Function }
  },
  data() {
    return {
      disabledDates: {
        to: subDays(new Date(), 1)
      },
      showEndTimePicker: false,
      isTaskMenuOpen: false,
      isDeletingTask: false,
      attrs: [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'outline'
          },
          dates: new Date()
        }
      ]
    }
  },
  watch: {
    selectedEndTime(val) {
      if (val) {
        this.showEndTimePicker = true
      } else {
        this.showEndTimePicker = false
      }
    }
  },
  computed: {
    selectedEndDate: {
      get() {
        return this.task.endDate ? parseISO(this.task.endDate) : null
      },
      set(date) {
        this.setEndDate(date)
      }
    },
    selectedEndTime: {
      get() {
        if (this.task.endDate && hasTime(this.task.endDate)) {
          return toHourMinute(this.task.endDate)
        } else {
          return null
        }
      },
      set(time) {
        this.setEndTime(time)
      }
    }
  },
  methods: {
    getDate(n) {
      return format(addDays(new Date(), n), 'yyyy-MM-dd')
    },
    openStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.startDatePicker.showCalendar()
        this.$refs.startDatePicker.$el.querySelector('input').focus()
      })
    },
    openEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.endDatePicker.showCalendar()
        this.$refs.endDatePicker.$el.querySelector('input').focus()
      })
    },
    toggleEndTimePicker() {
      this.showEndTimePicker = !this.showEndTimePicker
      if (this.showEndTimePicker) {
        this.$nextTick(() => this.$refs.timepicker.focus())
      } else {
        this.selectedEndTime = ''
      }
    },
    setEndDate(date) {
      let endDateString = null
      if (this.task.endDate && hasTime(this.task.endDate)) {
        endDateString = setStringWithDate(this.task.endDate, date)
      } else {
        endDateString = date
      }
      this.task.patch({ data: { endDate: endDateString } })
    },
    setEndTime(time) {
      let endDate
      if (time) {
        console.log(this.task.endDate, time)
        endDate = setStringWithTime(this.task.endDate, time)
      } else {
        // Empty means clearing time from date
        this.showEndTimePicker = false
        endDate = this.task.endDate.split('T').shift()
      }
      this.task.patch({ data: { endDate: endDate } })
    },
    taskMenuOnShow() {
      this.isTaskMenuOpen = true
      if (this.selectedEndTime) {
        this.showEndTimePicker = true
      }
    },
    taskMenuOnHide() {
      if (!this.isDeletingTask && this.isTaskMenuOpen) {
        this.isTaskMenuOpen = false
      }
    },
    setFolder(folderId) {
      this.task.patch({ data: { folderId: folderId } })
    },
    onClickDelete() {
      this.isDeletingTask = true
      this.deleteTask()
    }
  }
}
</script>
