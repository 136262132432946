<template>
  <div class="px-2.5 mb-1 folder-editable"
    :class="{ 'flex': draggable }"
  >
    <router-link v-show="!isEditing" :to="'/app/' + link + '/' + folder._id" class="flex leading-tight group hover:pr-0" :class="{ 'router-link-exact-active': $route.params.folderId === folder._id, 'flex-1 pl-0': draggable }">
      <div :class="{ 'folder-drag-handle opacity-100 lg:opacity-0 p-1 cursor-grab group-hover:opacity-100': draggable }" class="flex items-center py-1 px-2 w-6 h-9 opacity-0">
        <img v-show="draggable" src="../assets/icons/icon-drag-handle.svg">
      </div>
      <span class="py-2 mr-auto">{{ folder.title }}</span>
      <div class="inline-block pt-2.5 pr-3 small-counter group-hover:hidden">{{ count > 0 ? count : '' }}</div>
      <div class="hidden justify-center items-center pr-2.5 pl-1.5 opacity-0 group-hover:flex group-hover:opacity-30" @click.prevent.stop="editFolder" title="Edit Folder">
        <feather-icon name="edit-3" size="20"/>
      </div>
    </router-link>
    <div v-show="isEditing" class="pt-1 pr-1.5 pb-3 pl-6">
      <form @submit.prevent="updateTitle" @click.stop>
        <div>
          <input placeholder="Folder Name" class="py-1 px-2 my-1 w-full rounded border border-gray-300" ref="title" v-model="title" type="text"/>
        </div>
        <div class="flex items-center mt-1">
          <a class="btn-icon-circle" @click.prevent="deleteFolder">
            <img alt="Delete" src="../assets/icons/icon-delete.svg"/>
          </a>
          <a class="btn-icon-circle" @click.prevent="isEditing = false">
            <img alt="Close" src="../assets/icons/icon-close.svg"/>
          </a>
          <button :disabled="!title" class="ml-auto btn" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    folder: Object,
    count: Number,
    link: String,
    draggable: Boolean
  },
  data() {
    return {
      isEditing: false,
      isDropdownOpen: true,
      title: this.folder.title
    }
  },
  computed: {
    ...mapGetters('folders', { findFoldersInStore: 'find' })
  },
  methods: {
    editFolder() {
      this.isEditing = true
      this.$nextTick(() => {
        this.$refs.title.focus()
      })
    },
    updateTitle() {
      this.folder.patch({ data: { title: this.title } })
      this.isEditing = false
    },
    deleteFolder() {
      const result = confirm('Deleting "' + this.folder.title + '".\nAre you sure?')
      if (result) {
        this.isEditing = false
        const nextRoute = this.$route.fullPath.split('/').slice(0, 3).join('/') + '/all'
        if (this.$route.fullPath !== nextRoute) this.$router.push(nextRoute)
        const folderChild = this.findFoldersInStore({ query: { parentId: this.folder._id } }).data
        if (folderChild.length > 0) {
          folderChild.forEach(folder => { folder.remove().catch(err => console.error(err)) })
        }
        this.deleteFolderItemsInNamespace(this.folder._id, 'tasks')
        this.deleteFolderItemsInNamespace(this.folder._id, 'notes')
        this.deleteFolderItemsInNamespace(this.folder._id, 'events')
        this.folder.remove().then(() => {
          console.log('removed')
        }).catch(err => console.error(err))
      }
    },
    deleteFolderItemsInNamespace(folderId, namespace) {
      const items = this.$store.getters[`${namespace}/find`]({ query: { folderId: this.folder._id } }).data
      if (items.length > 0) {
        items.forEach(item => { item.remove().catch(err => console.error(err)) })
      }
    }
  }
}
</script>
