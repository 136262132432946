import { mapState } from 'vuex'

import Sidebar from '@/components/Sidebar'

export default {
  components: {
    Sidebar
  },
  computed: {
    ...mapState({ openSidebar: 'openSidebar' })
  }
}
