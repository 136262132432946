<template>
  <div class="flex relative left-0 items-start py-px px-0 mb-1 max-w-4xl bg-white rounded-xl transition-all duration-200 task-item hover:bg-gray-50 focus-within:bg-gray-50 group">
    <task-checkbox
      class="mx-4 mt-3"
      v-model="completed"
      :priority="task.priority"
    />
    <div class="flex-1 py-1.5" :class="{ 'text-gray-400': completed }">
      <editable
        class="block pr-2 pb-px m-0 w-full leading-6 break-words border-b border-transparent lg:leading-7 task-title focus:border-gray-300"
        :class="{ 'line-through': completed }"
        :content="task.title"
        :readonly="completed || task.__isTemp"
        :linkify="true"
        @update="taskTitleUpdate"
        placeholder="Untitled task"
      />
      <div>
        <span
          v-if="task.__isTemp"
          class="inline-block pb-1 mr-2 text-sm leading-none text-gray-600 whitespace-nowrap lg:leading-tight lg:text-sm"
        >
          Pending Sync
        </span>
        <span
          v-if="translateDate"
          class="inline-block mr-2 text-sm leading-none whitespace-nowrap lg:leading-tight lg:text-sm"
          v-tooltip.top="dateLeft(task.endDate)"
          :class="[ dateIsOverdue(task.endDate) ? 'text-red-600 border-b border-red-600 pb-px': 'text-blue-700 pb-1' ]"
        >
          {{ translateDate }}
        </span>
        <span
          class="inline-block pb-1 mr-2 text-sm leading-none text-gray-600 whitespace-nowrap lg:leading-tight lg:text-sm"
          v-if="taskFolder && showFolder(task.folderId)"
        >
          {{ taskFolder.title }}
        </span>
        <a
          @click.prevent="$router.push(noteLink)"
          title="Go to note"
          class="inline-block mr-2 text-sm leading-none text-gray-600 whitespace-nowrap border-b border-gray-400 lg:leading-tight lg:text-sm hover:text-black hover:border-black"
          v-show="task.noteId"
        >
          Note
        </a>
      </div>
    </div>
    <task-control
      v-if="!completed && !task.__isTemp"
      class="h-7 lg:h-10"
      :folders="folders"
      :task="task"
      :taskFolder="taskFolder"
      :deleteTask="deleteTask"
      :setPriority="setPriority"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { addDays, differenceInDays, format, isBefore, isSameDay, isSameISOWeek, isValid, parseISO, startOfDay, subDays } from 'date-fns'
import { debounce } from 'lodash-es'
import { hasTime } from '../utils'
import Editable from './Editable'
import TaskCheckbox from './TaskCheckbox'
import TaskControl from './TaskControl'

export default {
  name: 'Task',
  props: ['task', 'draggable', 'selectedFolder', 'folders'],
  components: {
    Editable,
    TaskCheckbox,
    TaskControl
  },
  mounted() {
    // this.removeNotExistInNote()
  },
  watch: {
    connected(val) {
      if (val && this.task.__isTemp) {
        this.task.save()
      }
    }
  },
  computed: {
    ...mapGetters('folders', { getFolderInStore: 'get' }),
    ...mapGetters('notes', { getNoteInStore: 'get' }),
    completed: {
      get() {
        return this.task.completed
      },
      set(value) {
        this.task.patch({ data: { completed: value } })

        if (this.task.noteId) {
          const note = this.getNoteInStore(this.task.noteId)
          const doc = new DOMParser().parseFromString(note.content, 'text/html')
          const list = doc.querySelectorAll(`[data-block-id="${this.task._id}"]`)
          if (list[0]) {
            list[0].setAttribute('data-done', value)
            note.patch({ data: { content: doc.activeElement.innerHTML } })
          }
        }
      }
    },
    connected() {
      return this.$store.state.connected
    },
    taskFolder() {
      return this.getFolderInStore(this.task.folderId)
    },
    noteLink() {
      if (!['today', 'upcoming', 'all', 'desk'].some(s => this.$route.fullPath.includes(s))) {
        return `/app/notes/${this.$route.params.folderId}/${this.task.noteId}`
      } else {
        return `/app/notes/all/${this.task.noteId}`
      }
    },
    translateDate() {
      const task = this.task
      const endDate = isValid(parseISO(task.endDate)) ? parseISO(task.endDate) : null

      if (task.completed || !endDate) { return }

      if (endDate && this.dateIsOverdue(endDate)) {
        if (hasTime(endDate)) {
          return format(endDate, 'dd MMM HH:mm')
        }
        return format(endDate, 'dd MMM')
      }

      if (endDate) {
        if (hasTime(task.endDate)) {
          if (isBefore(endDate, startOfDay(addDays(new Date(), 7)))) {
            if (isSameDay(endDate, new Date())) {
              return format(endDate, 'HH:mm')
            } else if (isSameDay(endDate, addDays(new Date(), 1))) {
              return format(endDate, "'Tomorrow' HH:mm")
            } else {
              return format(endDate, 'EEE HH:mm')
            }
          }
          return format(endDate, 'dd MMM HH:mm')
        } else {
          if (this.$route.fullPath.includes('desk')) {
            return ''
          }
          if (isSameDay(endDate, new Date())) {
            return 'Today'
          } else if (isSameDay(endDate, addDays(new Date(), 1))) {
            return 'Tomorrow'
          } else if (isSameISOWeek(endDate, addDays(new Date(), 7))) {
            return format(endDate, 'EEEE')
          } else if (isBefore(endDate, new Date())) {
            return 'Overdue'
          } else {
            return format(endDate, 'dd MMM')
          }
        }
      }
      return ''
    },
    formatUpdatedAt() {
      const updatedAt = parseISO(this.task.updatedAt)
      if (isSameDay(updatedAt, new Date())) {
        return format(updatedAt, 'HH:mm')
      } else if (isSameDay(updatedAt, subDays(new Date(), 1))) {
        return format(updatedAt, 'dd MMM HH:mm')
      } else if (isSameISOWeek(updatedAt, subDays(new Date(), 7))) {
        return format(updatedAt, 'dd MMM')
      } else {
        return format(updatedAt, 'dd MMM YYYY')
      }
    }
  },
  methods: {
    showFolder(folder) {
      if (folder === '' || this.selectedFolder._id === folder) return false
      if (this.taskFolder.title === 'Inbox') return false
      if (this.selectedFolder._id !== folder) return true
      return false
    },
    taskTitleUpdate: debounce(function (val) {
      if (this.task.title !== val) {
        const cleanTitle = val.replace(/(\r\n|\n|\n\n|\r)/gm, '')
        this.task.patch({ data: { title: cleanTitle } })

        if (this.task.noteId) {
          const note = this.getNoteInStore(this.task.noteId)
          // turn note into dom, find task inside note with task._id then save
          const doc = new DOMParser().parseFromString(note.content, 'text/html')
          const list = doc.querySelectorAll(`[data-block-id="${this.task._id}"]`)
          if (list[0]) {
            list[0].innerHTML = cleanTitle.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[\da-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=,]*))/gi, "<a href='$1' target='_blank' rel='noopener noreferrer nofollow'>$1</a>")
            note.patch({ data: { content: doc.activeElement.innerHTML } })
          }
        }
      }
    }, 500),
    dateLeft(d) {
      const date = parseISO(d)
      if (this.dateIsOverdue(date) || isSameDay(date, new Date())) {
        return format(date, 'd MMM')
      }
      const diff = differenceInDays(addDays(date, 1), new Date())
      let unit = 'days'
      if (diff === 1) unit = 'day'
      return format(date, 'd MMM') + ' (' + diff + ' ' + unit + ' left)'
    },
    dateIsOverdue(date) {
      return isBefore(new Date(date), startOfDay(new Date()))
    },
    setPriority(num) {
      this.task.patch({ data: { priority: num } })

      if (this.task.noteId) {
        const note = this.getNoteInStore(this.task.noteId)
        const doc = new DOMParser().parseFromString(note.content, 'text/html')
        const list = doc.querySelectorAll(`[data-block-id="${this.task._id}"]`)
        if (list[0]) {
          list[0].setAttribute('data-priority', num)
          note.patch({ data: { content: doc.activeElement.innerHTML } })
        }
      }
    },
    deleteTask() {
      const result = confirm('Deleting "' + this.task.title + '".\nAre you sure?')
      if (result) {
        if (this.task.noteId) {
          const { Note } = this.$FeathersVuex.api
          const note = Note.getFromStore(this.task.noteId)
          const doc = new DOMParser().parseFromString(note.content, 'text/html')
          const list = doc.querySelectorAll(`[data-block-id="${this.task._id}"]`)
          if (list[0]) {
            list[0].parentNode.removeChild(list[0])
            note.patch({ data: { content: doc.activeElement.innerHTML } })
          }
        }

        if (this.task.__isTemp) {
          // Promise from service.create hasn't been resolved,
          // will continue with error when reconnect because temp has been removed
          // Maybe don't allow temps to be removed?
          console.log('Please wait for task to be synced')
          // this.$store.commit('tasks/removeTemps', [ this.task.__id ])
        } else {
          const { Task } = this.$FeathersVuex.api
          const task = new Task({ id: this.task._id })
          task.remove().catch(err => console.log('err removing', err))
        }
      }
    },
    removeNotExistInNote() {
      if (this.task.noteId) {
        const { Note, Task } = this.$FeathersVuex.api
        const note = Note.getFromStore(this.task.noteId)
        if (note) {
          const exist = note.content.includes(this.task._id)
          if (!exist) {
            const task = new Task(this.task)
            task.remove()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">

.task-title {
  a {
    text-decoration: underline;
  }
}

</style>
