<template>
  <div class="flex-1">
    <h1 class="pl-4 page-headline">{{ todayDate }}</h1>
    <div v-if="todayEvents.length" class="py-0.5 px-3 mx-0 mt-4 mb-4 bg-blue-50 rounded-lg">
      <div v-for="event in todayEvents"
        :key="event._id"
        class="my-3 mr-0 ml-1.5"
      >
        <span class="mr-2 text-sm tabular-nums text-blue-700">{{ translateDate(event) }}</span>
        <span class="text-gray-700">{{ event.title }}</span>
      </div>
    </div>
    <div v-if="isFindTodayTasksPending && !haveTodayTasksBeenRequestedOnce" class="relative w-full h-20">
      <loading size="small"/>
    </div>
    <template v-else>
      <div class="mb-20">
        <task-draggable :tasks="sortTasks(todayTasks)" :date="getDate(0)" :folders="sortedFolders" :selected-folder="selectedFolder">
          <task-form :end-date="getToday" :folders="sortedFolders" :selected-folder="selectedFolder" :loading="isFindFoldersPending"></task-form>
        </task-draggable>
      </div>
      <div class="mb-20" v-if="settings.deskShowTomorrowTasks">
        <h3 class="mt-0 mr-0 mb-1 ml-4">Tomorrow</h3>
        <div v-if="tomorrowEvents.length" class="py-0.5 px-3 mx-0 mt-4 mb-4 bg-blue-50 rounded-lg">
          <div v-for="event in tomorrowEvents"
            :key="event._id"
            class="my-3 mr-0 ml-1.5"
          >
            <span class="mr-2 text-sm tabular-nums text-blue-700">{{ translateDate(event) }}</span>
            <span class="text-gray-700">{{ event.title }}</span>
          </div>
        </div>
        <div class="section-date" v-if="haveFoldersBeenRequestedOnce">
          <task-draggable :tasks="sortTasks(tomorrowTasks)" :date="getDate(1)" :folders="sortedFolders" :selected-folder="selectedFolder">
            <task-form :end-date="getDate(1)" :folders="sortedFolders" :selected-folder="selectedFolder" :loading="isFindFoldersPending"></task-form>
          </task-draggable>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import { addDays, format, isSameDay, parseISO, formatISO, startOfTomorrow, endOfTomorrow, startOfToday, endOfToday } from 'date-fns'
import { sortBy } from 'lodash-es'
import { getToday, hasTime } from '../utils'

import Folders from '@/mixins/Folders'
import Settings from '@/mixins/Settings'

import Loading from '@/components/Loading'
import TaskForm from '@/components/TaskForm'
import TaskDraggable from '@/components/TaskDraggable'

export default {
  name: 'DeskTasks',
  mixins: [
    Folders,
    Settings,
    makeFindMixin({ service: 'tasks', name: 'todayTasks', watch: true }),
    makeFindMixin({ service: 'tasks', name: 'tomorrowTasks', watch: true }),
    makeFindMixin({ service: 'events', watch: true })
  ],
  components: {
    Loading,
    TaskForm,
    TaskDraggable
  },
  data() {
    return {
      now: new Date()
    }
  },
  computed: {
    ...mapGetters(['connected']),
    ...mapGetters('auth', { user: 'user' }),
    getToday,
    todayDate() { return format(this.now, 'EEEE, d MMMM yyyy') },
    todayTasksParams() {
      return {
        query: {
          $or: [
            {
              endDate: { $lt: formatISO(endOfToday()), $nin: [null, ''] },
              completed: false
            },
            {
              endDate: { $gte: formatISO(startOfToday()), $lt: formatISO(endOfToday()), $nin: [null, ''] },
              completed: true
            },
            {
              endDate: formatISO(startOfToday(), { representation: 'date' }),
              completed: true
            }
          ],
          $sort: { priority: 1, updatedAt: 1 }
        },
        temps: !this.connected
      }
    },
    tomorrowTasksParams() {
      return {
        query: {
          $or: [
            {
              endDate: { $gte: formatISO(startOfTomorrow()), $lte: formatISO(endOfTomorrow()) }
            },
            {
              endDate: formatISO(startOfTomorrow(), { representation: 'date' })
            }
          ],
          $sort: { priority: 1, updatedAt: 1 }
        },
        temps: !this.connected
      }
    },
    todayTasksQueryWhen() { return this.connected },
    tomorrowTasksQueryWhen() { return this.connected },
    eventsParams() { return { query: {}, temps: !this.connected } },
    eventsQueryWhen() { return this.connected },
    todayEvents() {
      return this.sortEvents(this.events.filter(event => isSameDay(parseISO(event.end), this.now)))
    },
    tomorrowEvents() {
      return this.sortEvents(this.events.filter(event => isSameDay(parseISO(event.end), addDays(this.now, 1))))
    }
  },
  methods: {
    sortTasks(arr) {
      return sortBy(arr, [
        a => a.completed ? 1 : -1,
        a => -hasTime(a.endDate),
        a => parseISO(a.endDate),
        'priority'
      ])
    },
    sortEvents(arr) {
      return sortBy(arr, [
        a => parseISO(a.end),
        a => hasTime(a.end)
      ])
    },
    getDate(n) { return format(addDays(this.now, n), 'yyyy-MM-dd') },
    translateDate(event) {
      const { start, end, allDay } = event
      if (start && end && !allDay) {
        return format(parseISO(start), 'HH:mm') + ' – ' + format(parseISO(end), 'HH:mm')
      } else {
        return 'All Day'
      }
    }
  },
  mounted() {
    setInterval(() => { this.now = new Date() }, 1000 * 60)
  }
}
</script>
