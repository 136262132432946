<template>
  <div class="sidebar" :class="{ active: openSidebar }" @click.prevent="toggleSidebar">
    <div class="sidebar-menus">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  computed: {
    openSidebar() { return this.$store.state.openSidebar }
  },
  methods: {
    toggleSidebar() {
      if (document.body.clientWidth < 1024) { this.$store.commit('toggleSidebar') }
    }
  }
}
</script>
